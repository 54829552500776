import React, { createContext, useState, useContext } from 'react';

// Create Context
const SidebarContext = createContext();

// Create a custom hook to use the SidebarContext
export const useSidebar = () => {
  return useContext(SidebarContext);
};

// Create a provider component
export const SidebarProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const showSidebar = () => setVisible(true);
  const hideSidebar = () => setVisible(false);
  const toggleSidebar = () => setVisible(prev => !prev);

  return (
    <SidebarContext.Provider value={{ visible, showSidebar, hideSidebar, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};
