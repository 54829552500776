import React, { useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import Botttombar from '../../BottomBar/Botttombar';
import TopSideBar from '../../CommonTopBar/TopSideBar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button'; // Import Button if you want to add a submit button
import { apiCall } from '../Backend/Api';

export default function ChangePassword() {
  const [width, height] = useWindowSize();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async() => {
    if (newPassword === '') {
      setError('New Password is required.');
    } else if (newPassword !== retypePassword) {
      setError('Passwords do not match.');
    } else {
      setError(''); // Clear error if validation passes
      
let data ={
    current:oldPassword,
    password:newPassword,
    confirm:retypePassword,
}
      const response = await apiCall('/change_password',data)
    //   alert(response.message)
    }
  };

  return (
    <div style={{ height, display: 'flex', flexDirection: 'column' }}>
      <TopSideBar name="Change Password" />
      <div style={{ flex: 1, padding: 10 }}>
        <InputText 
          placeholder='Old Password' 
          style={{ width: '100%', marginBottom: 10 }} 
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type="password"
        />
        <InputText 
          placeholder='New Password' 
          style={{ width: '100%', marginBottom: 10 }} 
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
        />
        <InputText 
          placeholder='Re-Type New Password' 
          style={{ width: '100%', marginBottom: 10 }} 
          value={retypePassword}
          onChange={(e) => setRetypePassword(e.target.value)}
          type="password"
        />
        
        {error && <div style={{ color: 'red', marginBottom: 10 }}>{error}</div>}
        <div style={{display:"flex",justifyContent:'center'}}>
        <Button label="Change Password" onClick={handleSubmit} style={{alignSelf:'center'}} />
        </div>
      </div>
      <Botttombar />
    </div>
  );
}
