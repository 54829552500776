import React from 'react';
import { RxDragHandleHorizontal } from 'react-icons/rx';

export default function EditCard({ data}) {
 
  
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        padding: 20,
        paddingBottom: 15,
        borderWidth: 1,
        borderColor: '#ccc',
        borderBottomStyle: 'solid',
        backgroundColor: 'white',
      }}
    >
      <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
        <RxDragHandleHorizontal size={20} />
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 5 }}>
        <div>{data?.name}</div>
        <div style={{ fontSize: 12, color: '#888' }}>{data?.detail}</div>
      </div>
    </div>
  );
}
