// MessageContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';
import { apiCall } from '../Backend/Api';

// Create Context
const MessageContext = createContext();

// Create Provider Component
export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  

  useEffect(()=>{
    loadMessage()
  },[])

  // Function to add a new message
  const loadMessage = async(message) => {
    const response = await apiCall("/load_messages");

   setMessages(response?.data)
    // setMessages((prevMessages) => [...prevMessages, message]);
  };

  const readMessage =async(message_id)=>{
    let data={
      message_id:message_id
    }
    const response = await apiCall("/read_messages",data);

    loadMessage()
  }

  return (
    <MessageContext.Provider value={{ messages, readMessage, loadMessage }}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook to use the message context
export const useMessages = () => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return context;
};
