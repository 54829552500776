import { useWindowSize } from "@react-hook/window-size";
import React, { useEffect, useState } from "react";
import BuysellTopcard from "./BuysellTopcard";

import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Theme } from "../../Theme/Theme";
import { TradeApi } from "../Backend/TradeApi";
import { useSocket } from "../../Socket/SocketContext";
import BidAskCard from "../Home/BidAskCard";
import { useBuySell } from "./BuySellContext";
import moment from "moment";

export default function BuySell() {
  const [width, height] = useWindowSize();
  const [value, setValue] = useState(25);
  const [offsets, setOffsets] = useState([-25, 25, -500, 500, -1000, 1000]);
  const [loading, setLoading] = useState(false);
  const [scriptdata, setscriptdata] = useState(
    JSON.parse(localStorage.getItem("script")) || {}
  );
  const { subscribeToSymbol, socket } = useSocket();
  const [bidaskdata, setBidaskdata] = useState({});
  const { startLoading, triggerSuccess, triggerError } = useBuySell();

  window.setscriptdata = setscriptdata;

  const scriptId = scriptdata.script_id;

  const handleTrade = (data) => {
    setBidaskdata((prevData) => ({ ...prevData, ...data }));
  };

  const handleBidask = (data) => {
    setBidaskdata((prevData) => ({
      ...prevData,
      Bid: data.Bid,
      Ask: data.Ask,
    }));
  };

  // const scriptData = data[scriptId] || {};

  useEffect(() => {
    socket?.on(`trade${scriptId}`, handleTrade);
    socket?.on(`bidask${scriptId}`, handleBidask);

    socket?.emit("giverate", scriptId);

    return () => {
      socket?.off(`trade${scriptId}`, handleTrade);
      socket?.off(`bidask${scriptId}`, handleBidask);
    };
  }, [scriptId, socket]);

  // const [bidask,setBidask] = useState({})
  const [price, SetPrice] = useState(0);

  // useEffect(()=>{
  //   subscribeToSymbol(scriptdata.script_id)
  // },[])

  // useEffect(()=>{
  //   setBidask(data[scriptdata.script_id])
  // },[data[scriptdata.script_id],scriptdata])

  // useEffect(()=>{
  //     generateOffsets(value)
  // },[value])

  const [selectedCity, setSelectedCity] = useState({
    name: "Market Execution",
    code: "0",
  });
  const cities = [
    { name: "Market Execution", code: "0" },
    { name: "Buy Limit", code: "1" },
    { name: "Sell Limit", code: "2" },
    { name: "Buy Stop", code: "3" },
    { name: "Sell Stop", code: "4" },
  ];

  const TradeExecution = async (a) => {
    setLoading(true);

    startLoading();

    var bss = a;
    if (selectedCity.code == "1" || selectedCity.code == "3") {
      bss = "0";
    }
    if (selectedCity.code == "2" || selectedCity.code == "4") {
      bss = "1";
    }
    let data = {
      bs: bss,
      punch: value,
      segi: "eq",
      sid: scriptdata.script_id,
      at_price: price,
      type: selectedCity.code,
      tick: bidaskdata || {},
    };
    let response;
    if (selectedCity.code != "0") {
      response = await TradeApi("/pending", data);
      // alert(JSON.stringify(response))
    } else {
      response = await TradeApi("/directtrade", data);
      // alert(JSON.stringify(response))
    }

    if (response.success == "True") {
      triggerSuccess(response.msg);
    } else {
      triggerError(response.msg);
    }
  };

  // const generateOffsets = (value) => {
  //     const num = parseFloat(value);
  //     if (isNaN(num)) return;

  //     const offsetValues = [];
  //     const factors = [0.1, 1, 10];

  //     factors.forEach(factor => {
  //       offsetValues.push(Math.round(-factor * num));
  //       offsetValues.push(Math.round(factor * num));
  //     });

  //     setOffsets(offsetValues);
  //   };

  const handleChange = (e) => {
    if (e.target.value < 100000 && e.target.value >= 0) {
      setValue(e.target.value);
    } else if (e.target.value >= 100000) {
      setValue(100000);
    }

    // generateOffsets(e.target.value)
  };
  const handleChange2 = (e) => {
    if (e < 100000 && e >= 0) {
      setValue(e);
    } else if (e >= 100000) {
      setValue(100000);
    }

    // generateOffsets(e)
  };

  return (
    <div style={{ height: height, display: "flex", flexDirection: "column" }}>
      <BuysellTopcard />
      <div
        style={{ padding: 5, width: "100%", paddingLeft: 20, paddingRight: 20 }}
      >
        <Dropdown
          style={{
            width: "100%",
            height: "100%",
            borderWidth: 0,
            background: "rgba(0,0,0,0.04)",
            textAlign: "center",
          }}
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.value)}
          options={cities}
          optionLabel="name"
          placeholder="Select Trade Type"
          className="w-full md:w-14rem"
        />
      </div>

      <div
        style={{
          height: 50,
          display: "flex",
          alignItems: "center",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        {/* <Button onClick={()=>handleChange2(parseFloat(value)+offsets[4])} label={offsets[4]} text style={{padding:0,flex:2,fontSize:14}}/> */}
        <Button
          onClick={() => handleChange2(parseFloat(value) + offsets[2])}
          label={offsets[2]}
          text
          style={{ padding: 0, flex: 2, fontSize: 14 }}
        />
        <Button
          onClick={() => handleChange2(parseFloat(value) + offsets[0])}
          label={offsets[0]}
          text
          style={{ padding: 0, flex: 2, fontSize: 14 }}
        />

        <div style={{ flex: 4 }}>
          <InputText
            value={value}
            onChange={handleChange}
            type="number"
            placeholder="Not Set"
            style={{
              width: "100%",
              borderStyle: "hidden",
              textAlign: "center",
              fontWeight: "bold",
            }}
          />
        </div>

        <Button
          onClick={() => handleChange2(parseFloat(value) + offsets[1])}
          label={"+" + offsets[1]}
          text
          style={{ padding: 0, flex: 2, fontSize: 14 }}
        />
        <Button
          onClick={() => handleChange2(parseFloat(value) + offsets[3])}
          label={"+" + offsets[3]}
          text
          style={{ padding: 0, flex: 2, fontSize: 14 }}
        />
        {/* <Button onClick={()=>handleChange2(parseFloat(value)+offsets[5])} label={"+"+offsets[5]} text style={{padding:0,flex:2,fontSize:14}}/> */}
      </div>
      <div
        style={{
          height: 50,
          display: "flex",
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BidAskCard bid={bidaskdata?.Bid} center />
        <BidAskCard bid={bidaskdata?.Ask} center />
      </div>

      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        {selectedCity.code != "0" && (
          <InputText
            value={price}
            type="number"
            onChange={(e) => SetPrice(e.target.value)}
            placeholder="PRICE"
            style={{
              width: "100%",
              borderStyle: "solid",
              textAlign: "center",
              fontWeight: "bold",
            }}
          />
        )}
      </div>

      <div style={{ height: 55, display: "flex", marginBottom: 2 }}>
        {selectedCity.code == "0" ? (
          <>
            <Button
              onClick={() => TradeExecution("1")}
              style={{
                padding: 0,
                flex: 1,

                borderRadius: 0,
                borderColor: Theme.MEDIUMGRAY,
                borderLeftWidth: 0,
              }}
              severity="danger"
              label="SELL"
              outlined
            />
            <Button
              onClick={() => TradeExecution("0")}
              style={{
                padding: 0,
                flex: 1,
                borderRadius: 0,
                borderColor: Theme.MEDIUMGRAY,
              }}
              label="BUY"
              outlined
            />
          </>
        ) : (
          <Button
            onClick={() => TradeExecution("1")}
            style={{
              padding: 0,
              flex: 1,
              marginTop: 10,
              height: 50,
              marginBottom: 10,
              borderRadius: 0,
              // borderColor: Theme.FONTCOLOR,
              borderLeftWidth: 0,
              // color: Theme.FONTCOLOR,
            }}
            severity={
              selectedCity.code == "1" || selectedCity.code == "3"
                ? "primary"
                : "danger"
            }
            label="PLACE ORDER"
          />
        )}
      </div>
      <div style={{ marginTop: 20, fontSize: 14 }}>
        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
          }}
        >
          <div style={{ flex: 1 }}>Trading Type</div>
          <div>Qty Wise</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Segment</div>
          <div>Equity</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Tick Size</div>
          <div>1</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Symbol</div>
          <div>NSE/{scriptdata.name}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>LTP</div>
          <div>{bidaskdata.LTP}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>High</div>
          <div>{bidaskdata.High}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Low</div>
          <div>{bidaskdata.Low}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Open</div>
          <div>{bidaskdata.Open}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Previous Close</div>
          <div>{bidaskdata.Previous_Close}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Volume</div>
          <div>{bidaskdata.Volume}</div>
        </div>

        <div
          style={{
            display: "flex",
            paddingLeft: 10,
            paddingRight: 10,
            borderBottomStyle: "dotted",
            paddingBottom: 2,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 5,
          }}
        >
          <div style={{ flex: 1 }}>Tick Time</div>
          <div>{moment(bidaskdata.Timestamp).format("DD-MM-YY HH:mm:ss")}</div>
        </div>
      </div>
    </div>
  );
}
