import React, { createContext, useContext, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import { Theme } from '../../Theme/Theme';
import { GoCheckCircleFill } from 'react-icons/go';
import { Button } from 'primereact/button';

import successSound from '../../Sound/succes.mp3';
import errorSound from '../../Sound/error.mp3';

// Create a context for Buy/Sell operations
const BuySellContext = createContext();

export const BuySellProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message,setMessage] = useState("")

  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);
  
  const triggerSuccess = (data) => {
    setSuccess(true);
    setMessage(data)
    playSound(successSound); //
    stopLoading();
    setTimeout(() => setSuccess(false), 1000); // Auto hide after 3 seconds
  };

  const triggerError = (data) => {
    console.log(data)
    setError(true);
    playSound(errorSound); //
    stopLoading();
    setMessage(data)
  };

  const closeError = () => setError(false);
  const playSound = (soundFile) => {
    const audio = new Audio(soundFile); // Create a new audio instance
    audio.play().catch((error) => console.error("Error playing sound:", error));
  };


  return (
    <BuySellContext.Provider
      value={{
        loading,
        success,
        error,
        startLoading,
        stopLoading,
        triggerSuccess,
        triggerError,
        closeError,
      }}
    >
      {children}
      {loading && <LoadingScreen />}
      {success && <SuccessScreen message={message} />}
      {error && <ErrorScreen closeError={closeError} message={message}/>}
    </BuySellContext.Provider>
  );
};

export const useBuySell = () => useContext(BuySellContext);

// Loading, Success, and Error components
const LoadingScreen = () => (
  <div style={styles.fullScreen}>
    <h2>Loading...</h2>
    {/* Add loading spinner or animation */}
  </div>
);




const SuccessScreen = ({message}) => {
  // Play success sound here
  // Example: new Audio('success-sound.mp3').play();

  return (
    <div style={styles.fullScreen}>
      
      <GoCheckCircleFill color={Theme.FONTCOLOR} size={65}/>
      {message}
    
    </div>
  );
};

const ErrorScreen = ({ closeError,message }) => {
  // Play error sound here
  // Example: new Audio('error-sound.mp3').play();

  return (
    <div style={styles.fullScreen}>
  <IoIosCloseCircle color={Theme.REDCOLOR} size={65}/>
    {message}

    <Button onClick={closeError} style={{padding:10,borderRadius:0,borderColor:Theme.MEDIUMGRAY,width:'50%',position:'absolute',bottom:10}} severity="secondary" label="CLOSE" outlined />
    </div>
  );
};

// Styles for full-screen overlay
const styles = {
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,1)',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99999999,
  },
};
