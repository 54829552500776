import { useWindowSize } from "@react-hook/window-size";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Theme } from "../../Theme/Theme";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useBuySell } from "../BuySell/BuySellContext";
import { TradeApi } from "../Backend/TradeApi";
import { useSocket } from "../../Socket/SocketContext";
import BidAskCard from "../Home/BidAskCard";

export default function EditPending() {
  const [width, height] = useWindowSize();

  const [scriptdata, setscriptdata] = useState(
    JSON.parse(localStorage.getItem("editpending")) || {}
  );

  const [price, setPrice] = useState(parseFloat(scriptdata.price));
  const [qty, setQty] = useState(parseFloat(scriptdata.qty));

  const navigate = useNavigate();
  const { subscribeToSymbol, socket } = useSocket();
  const scriptId = scriptdata.script_id;

  const [bidaskdata, setBidaskdata] = useState({});
  const { startLoading, triggerSuccess, triggerError } = useBuySell();

  const [selectedCity, setSelectedCity] = useState();
  const cities = [
    { name: "Buy Limit", code: "1" },
    { name: "Sell Limit", code: "2" },
    { name: "Buy Stop", code: "3" },
    { name: "Sell Stop", code: "4" },
  ];

  const handleTrade = (data) => {
    setBidaskdata((prevData) => ({ ...prevData, ...data }));
  };

  const handleBidask = (data) => {
    setBidaskdata((prevData) => ({
      ...prevData,
      Bid: data.Bid,
      Ask: data.Ask,
    }));
  };

  useEffect(() => {
    socket?.on(`trade${scriptId}`, handleTrade);
    socket?.on(`bidask${scriptId}`, handleBidask);

    socket?.emit("giverate", scriptId);

    return () => {
      socket?.off(`trade${scriptId}`, handleTrade);
      socket?.off(`bidask${scriptId}`, handleBidask);
    };
  }, [scriptId, socket]);

  const TradeExecution = async (a) => {
    if (selectedCity?.code > 0) {
      startLoading();

      var bss = a;
      if (selectedCity.code == "1" || selectedCity.code == "3") {
        bss = "0";
      }
      if (selectedCity.code == "2" || selectedCity.code == "4") {
        bss = "1";
      }
      let data = {
        bs: bss,
        punch: qty,
        segi: "eq",
        sid: scriptdata.script_id,
        at_price: price,
        type: selectedCity.code,
        tick: bidaskdata || {},
        pending_id: scriptdata.id,
      };

      let response = await TradeApi("/editpending", data);
      // alert(JSON.stringify(response))

      if (response.success == "True") {
        triggerSuccess(response.msg);
      } else {
        triggerError(response.msg);
      }
    } else {
      triggerError("Select Proper TradeType");
    }
  };

  return (
    <div style={{ height: height }}>
      <div style={{ height: 50, display: "flex", alignItems: "center" }}>
        <div
          onClick={() => navigate(-1)}
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaArrowLeft size={20} />
        </div>

        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 18 }}>
            {scriptdata.name && scriptdata.name}
            {scriptdata.symbol && scriptdata.symbol}
          </div>
          <div style={{ fontSize: 13, color: Theme.MEDIUMGRAY }}>
            Edit Pending {"#" + scriptdata.id}
          </div>
        </div>
      </div>
      <div
        style={{ padding: 5, width: "100%", paddingLeft: 20, paddingRight: 20 }}
      >
        <Dropdown
          style={{
            width: "100%",
            height: "100%",
            borderWidth: 0,
            background: "rgba(0,0,0,0.04)",
            textAlign: "center",
          }}
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.value)}
          options={cities}
          optionLabel="name"
          placeholder="Select Trade Type"
          className="w-full md:w-14rem"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 5,
        }}
      >
        <div style={{ flex: 1 }}>At Price</div>
        <InputText
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
          placeholder="PRICE"
          style={{
            width: "100%",
            borderStyle: "solid",
            textAlign: "center",
            fontWeight: "bold",
            flex: 2,
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 5,
        }}
      >
        <div style={{ flex: 1 }}>Qty</div>
        <InputText
          value={qty}
          type="number"
          onChange={(e) => setQty(e.target.value)}
          placeholder="QTY"
          style={{
            width: "100%",
            borderStyle: "solid",
            textAlign: "center",
            fontWeight: "bold",
            flex: 2,
          }}
        />
      </div>

      <div
        style={{
          height: 50,
          display: "flex",
          paddingLeft: 20,
          paddingRight: 20,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BidAskCard bid={bidaskdata?.Bid} center />
        <BidAskCard bid={bidaskdata?.Ask} center />
      </div>

      <div
        style={{ height: 50, display: "flex", marginBottom: 2, marginTop: 5 }}
      >
        <Button
          onClick={TradeExecution}
          style={{
            padding: 0,
            flex: 1,
            borderRadius: 0,
            // borderColor: Theme.MEDIUMGRAY,
            borderLeftWidth: 0,
          }}
          severity={
            selectedCity?.code == "1" || selectedCity?.code == "3"
              ? "primary"
              : selectedCity?.code == "2" || selectedCity?.code == "4"
              ? "danger"
              : "secondary"
          }
          label="MODIFY ORDER"
          // outlined
        />
      </div>
    </div>
  );
}
