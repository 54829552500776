// TradeContext.js
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { apiCall } from '../Backend/Api';
import { useSocket } from '../../Socket/SocketContext';


// Create the context
const TradeContext = createContext();

// Provider component
export const TradeProvider = ({ children }) => {
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling
  const [profitLossData, setProfitLossData] = useState({}); // Script-wise profit/loss
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [m2mData, setM2mData] = useState({});
  const [totalM2m, setTotalM2m] = useState(0); // Total m2m
  const [credit, setCredit] = useState(0); 
  const [block, setBlock] = useState(0);// Total m2m
  const [percent, setPercent] = useState(0);// Total m2m

  const [balance,setBalance]=useState(0)

  const {subscribeToSymbol,data} = useSocket()
  
  const calculateProfitLoss = (currentRate, rate, qty, brokerage, buySell) => {
    const m2m = calculateM2M(currentRate, rate, qty, buySell);
    const profitLoss = m2m - (brokerage * currentRate*qty) / 100;
    return parseFloat(profitLoss).toFixed(2);
  };


  const calculateM2M = (currentRate, rate, qty, buySell) => {
    const ratediffrance = buySell == 0 ? currentRate - rate : rate - currentRate;
    return ratediffrance * qty;
  };

  const GetProfitLoss = (scriptId,rates) => {
    // console.log('trades:', trades); // Log trades to ensure it's available
    // alert('scriptId:', scriptId); // Log scriptId to ensure it's valid

    // Ensure trades is an array and has content
    if (!Array.isArray(trades) || trades.length === 0) {
        console.warn('Trades data is either not an array or empty.');
        return 0;
    }

    const trade = trades.find(trade => trade.script_id === scriptId);

    if (!trade) {
        console.warn('Trade not found for scriptId:', scriptId);
        return 0;
    }

    const { rate, qty, brokerage, buy_sell } = trade;

    // Get current rate
    let currentRate 
    if(buy_sell === 0){
     

      if(rates.Bid >0){
         currentRate  = rates.Bid
      }else{
        currentRate  = rates.LTP
      }

    }else{
      if(rates.Ask >0){
        currentRate  = rates.Ask
      }else{
        currentRate  = rates.LTP
      }

    }
  

 

    if (currentRate == null || currentRate === 0) {
        console.warn('Current rate and LTP are both null or zero for scriptId:', scriptId);
        return 0;
    }

    const profitLoss = calculateProfitLoss(currentRate, rate, qty, brokerage, buy_sell);

    return profitLoss;
}


useEffect(()=>{
  loadTrades()
},[])

  const updateProfitLoss = (scriptId, profitLoss, m2m) => {
    
    setProfitLossData((prevData) => {
      const updatedData = { ...prevData, [scriptId]: profitLoss };
     
      // Calculate total profit/loss after updating profit/loss data
      const total = Object.values(updatedData)
      .reduce((acc, pfl) => {
        // Convert to number and check validity
        const parsedPFL = parseFloat(pfl);
        if (!isNaN(parsedPFL)) {
          return acc + parsedPFL;
        }
        console.warn('Invalid value encountered:', pfl); // Debugging invalid values
        return acc;
      }, 0);

       
      setTotalProfitLoss(parseFloat(total).toFixed(2));

      return updatedData;
    });


    setM2mData((prevData) => {
        const updatedM2mData = { ...prevData, [scriptId]: m2m };
  
        // Calculate total m2m after updating m2m data
        const totalM2M = Object.values(updatedM2mData)
          .reduce((acc, m2mValue) => {
            const parsedM2M = parseFloat(m2mValue);
            return !isNaN(parsedM2M) ? acc + parsedM2M : acc;
          }, 0);
  
        setTotalM2m(parseFloat(totalM2M).toFixed(2));
  
        return updatedM2mData;
      });

  };

  // Load trades from API
  const loadTrades = async () => {
    setLoading(true);
    try {
      const response = await apiCall("/loadposition");
      console.log("positionloaded")
      setTrades(response?.data || []);
      setBalance(response.balance || 0)
      setCredit(response.credit || 0)
      setBlock(response.free_margin || 0)
      setPercent( parseFloat(response.free_margin /response.credit*100).toFixed(2)  || 0)

      response?.data.map((i,t)=>{
        subscribeToSymbol(i.script_id)
      })

    } catch (error) {
      console.error("Failed to load trades:", error);
      setError(error); // Set error if needed
    } finally {
      setLoading(false);
    }
  };

  return (
    <TradeContext.Provider value={{ trades, loadTrades, loading, error,totalProfitLoss ,updateProfitLoss,balance,totalM2m,credit,block,percent,GetProfitLoss}}>
      {children}
    </TradeContext.Provider>
  );
};

// Custom hook to use the TradeContext
export const useTradeContext = () => useContext(TradeContext);


////

// import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
// import { apiCall } from '../Backend/Api';
// import { useSocket } from '../../Socket/SocketContext';

// // Create the context
// const TradeContext = createContext();

// // Provider component
// export const TradeProvider = ({ children }) => {
//   const [trades, setTrades] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [profitLossData, setProfitLossData] = useState({});
//   const [totalProfitLoss, setTotalProfitLoss] = useState(0);
//   const [m2mData, setM2mData] = useState({});
//   const [totalM2m, setTotalM2m] = useState(0);
//   const [credit, setCredit] = useState(0);
//   const [block, setBlock] = useState(0);
//   const [percent, setPercent] = useState(0);
//   const [balance, setBalance] = useState(0);

//   const { subscribeToSymbol, data } = useSocket();

//   // Convert trades array to a Map for efficient lookups

  

  

  

//     // Update M2M data
//     setM2mData(prevData => {
//       const updatedM2mData = { ...prevData, [scriptId]: m2m };

//       const totalM2M = Object.values(updatedM2mData)
//         .reduce((acc, m2mValue) => {
//           const parsedM2M = parseFloat(m2mValue);
//           return !isNaN(parsedM2M) ? acc + parsedM2M : acc;
//         }, 0);

//       setTotalM2m(parseFloat(totalM2M).toFixed(2));

//       return updatedM2mData;
//     });

//     return  parseFloat(profitLoss).toFixed(2)
//   }, [data, trades]);

//   const loadTrades = async () => {
//     setLoading(true);
//     try {
//       const response = await apiCall("/loadposition");
//       const tradesData = response?.data || [];
//       setTrades(tradesData);
//       setBalance(response.balance || 0);
//       setCredit(response.credit || 0);
//       setBlock(response.free_margin || 0);
//       setPercent(
//         response.credit > 0 
//           ? parseFloat((response.free_margin / response.credit) * 100).toFixed(2)
//           : 0
//       );

//       tradesData.forEach(trade => {
//         subscribeToSymbol(trade.script_id)

        
//         updateProfitLoss(trade.script_id)
       
      
       
//      } );
//     } catch (error) {
//       console.error("Failed to load trades:", error);
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };

  





//   return (
//     <TradeContext.Provider
//       value={{
//         trades,
//         loadTrades,
//         loading,
//         error,
//         totalProfitLoss,
//         updateProfitLoss,
//         balance,
//         totalM2m,
//         credit,
//         block,
//         percent
//       }}
//     >
//       {children}
//     </TradeContext.Provider>
//   );
// };

// // Custom hook to use the TradeContext
// export const useTradeContext = () => useContext(TradeContext);
