import React from 'react'
import SearchServerCard from './SearchServerCard'
import { Auth } from '../Backend/Auth'

export default function SelectServer({onClick}) {

    const [servers,setServers] =React.useState([])
   

const searchserver = async (a)=>{
    if(a.length>1){

    const data={
        name:a
    }
const r = await Auth("/find_server",data)
setServers(r.data)

}
if(a.length==0){
    setServers([])
}
}
  return (
    <div style={{display:'flex',width:'100%',flexDirection:'column',height:'100%'}}>
    <div style={{borderStyle:'solid',borderRadius:5,borderColor:'lightgray',height:50,marginTop:10}}>
      <input onChange={(e)=>{
        searchserver(e.target.value)
        }} placeholder='Search Server' style={{width:'100%',height:'100%',borderWidth:0,borderRadius:5,paddingLeft:10}}/>
      </div>
      <div style={{flex:1,marginTop:10,overflowY:'scroll'}}>
       {servers&&servers.map((i,t)=>(
        <SearchServerCard onClick={()=>onClick(i)}  image={i.image} name={i.server} details={i.name}/>
       ))} 
        
       
      </div>
    </div>
  )
}
