import React, { useState } from "react";
import { Theme } from "../../Theme/Theme";
import { Button } from "primereact/button";
import { apiCall } from "../Backend/Api";
import { useNavigate } from "react-router-dom";

export default function PendingBottomBar({ symboldata, closesheet }) {
  const [limittype, setLimittype] = useState(symboldata.type);
  const navigate = useNavigate();

  const MofifyPending = () => {
    localStorage.setItem("editpending", JSON.stringify(symboldata));
    navigate("/editpending");
  };

  const canclePending = async () => {
    let data = {
      pending_id: symboldata.id,
    };

    let response = await apiCall("/cancel_pending", data);
    // alert(JSON.stringify(response))
    if (response) {
      alert(response.message);
      closesheet();
    }
  };

  return (
    <>
      <div
        style={{
          fontSize: 22,
          fontWeight: "bold",
          paddingTop: 10,
          display: "flex",
        }}
      >
        {symboldata.symbol}
        <span style={{ marginLeft: 10, fontSize: 12, flex: 1 }}>NSE</span>{" "}
        <span
          style={{
            marginLeft: 5,
            fontSize: 15,
            color:
              symboldata.buy_sell == "0" ? Theme.FONTCOLOR : Theme.REDCOLOR,
          }}
        >
          {symboldata.buy_sell == "0" ? "BUY" : "SELL"}
        </span>
      </div>

      <div style={{ display: "flex", marginTop: 5 }}>
        <div style={{ flex: 1, textTransform: "uppercase" }}>Pending Type</div>
        <span style={{ textTransform: "uppercase" }}>
          {symboldata.sl_tp_msg}
        </span>
      </div>
      <div style={{ display: "flex", marginTop: 5 }}>
        <div style={{ flex: 1 }}>Pending Rate</div>
        <span>{symboldata.price}</span>
      </div>

      <div style={{ display: "flex" }}></div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "fixed",
          bottom: 50,
          width: "100%",
          paddingRight: 20,
          gap: 10,
        }}
      >
        <Button
          onClick={MofifyPending}
          style={{
            padding: 5,
            borderRadius: 0,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 15,
            paddingLeft: 30,
            paddingRight: 30,
            fontSize: 14,
          }}
          severity="error"
          label="Modify Pending"
          outlined
        />
        <Button
          onClick={canclePending}
          style={{
            padding: 5,
            borderRadius: 0,
            borderColor: Theme.MEDIUMGRAY,
            marginTop: 15,
            paddingLeft: 30,
            paddingRight: 30,
            fontSize: 14,
          }}
          severity="danger"
          label="Cancel Pending"
          outlined
        />
      </div>
    </>
  );
}
