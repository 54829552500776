import React, { useState, useEffect } from 'react';
import { Theme } from '../../Theme/Theme';
import NumberDiffrance from './NumberDiffrance';

export default function BidAskCard({ hl, color, anem, high, bid = 0,center}) {
  const [prevBid, setPrevBid] = useState(bid);
  const [currentColor, setCurrentColor] = useState(Theme.MEDIUMGRAY); // Default color
  const [resetTimeout, setResetTimeout] = useState(null);

  useEffect(() => {
    // Determine color based on bid comparison
    let newColor;
    if (bid > prevBid) {
      newColor = Theme.FONTCOLOR; // Higher bid
    } else if (bid < prevBid) {
      newColor = Theme.REDCOLOR; // Lower bid
    } else {
      newColor = currentColor; // No change
    }

    setCurrentColor(newColor);

    // Clear any existing timeout
    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }

    // Set a new timeout to reset color after 2 seconds if the bid remains unchanged
    const timeoutId = setTimeout(() => {
      setCurrentColor(Theme.DARKGRAY); // Reset to default color
    }, 2000); // Duration to 2 seconds

    setResetTimeout(timeoutId);

    // Update previous bid value
    setPrevBid(bid);

    // Cleanup function to clear timeout on component unmount or bid change
    return () => {
      clearTimeout(timeoutId);
    };
  }, [bid, prevBid, currentColor]);

  // Split the bid into integer and decimal parts
  const splitBid = (value) => {
    const [integerPart, decimalPart] = value.toString().split('.');
    return { integerPart, decimalPart };
  };

  const formatBid = (value) => {
    const { integerPart, decimalPart } = splitBid(value);

    // If there is no decimal part, use the last two digits of the integer part
    if (!decimalPart) {
      const lastTwoDigits = integerPart.slice(-2);
      const rest = integerPart.slice(0, -2);
      return (
        <>
          {rest}
          <span style={{ fontSize: '1.5em' }}>{lastTwoDigits}</span>
        </>
      );
    }

    // If there is a decimal part, make it larger
    return (
      <>
        {integerPart}
        <span style={{ fontSize: '1.5em' }}>.{decimalPart}</span>
      </>
    );
  };

  return (
    <div
      style={{
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems:center?'center': 'end',
        color: currentColor,
        transition: 'color 0.5s ease', // Smooth transition
      }}
    >
      <div style={{ fontSize: 16 }}>
        {formatBid(bid)}
      </div>
      {hl&&
      <div style={{ fontSize: 14, color: 'gray' }}>
        {hl}: {high}
      </div>}
    </div>
  );
}
