import { useWindowSize } from '@react-hook/window-size'
import React from 'react'
import CommonTopBar from '../../CommonTopBar/CommonTopBar'
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import AddCard from './AddCard';
import { useWatchlist } from '../Home/WatchlistContext';

export default function AddSymbol() {
    const [width, height] = useWindowSize()
    const {allScripts, addToWatchlist } = useWatchlist();
    const [searchvalue,setSearchvalue] = React.useState("")
    const [filteredScripts, setFilteredScripts] = React.useState([]);
   

    React.useEffect(() => {
        if (searchvalue.length > 1) {
          const filtered = allScripts.filter(script =>
            script.name.toLowerCase().includes(searchvalue.toLowerCase()) ||
            script.details.toLowerCase().includes(searchvalue.toLowerCase())
          );
          setFilteredScripts(filtered);
        } else {
          setFilteredScripts(allScripts);
        }
      }, [searchvalue, allScripts]);

  return (
    <div style={{height:height,display:'flex',flexDirection:'column'}}>
        <CommonTopBar heading="Add Symbol"/>

        <div style={{marginLeft:10,marginRight:15}}>
                <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText onChange={(e)=>setSearchvalue(e.target.value)} style={{width:'100%',marginRight:0}} v-model="value1" placeholder="Search" />
            </IconField>
        </div>
        <div style={{flex:1,overflowY:'scroll',paddingTop:20}}>
        {filteredScripts&&filteredScripts.map((i,t)=>(
        
        <AddCard onClick={()=>addToWatchlist(i)} key={i.script_id} name={i.name} detail={i.details} search={searchvalue}/>
    )
        )}
            
            {/* <AddCard name="ARK INFRACON" detail={"ALOK ARK NI SITE"} search={"RK"}/>
            <AddCard/>
            <AddCard/>
            <AddCard/> */}
        </div>
      
    </div>
  )
}
