import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';

import Orders from './Orders';
import { Theme } from '../../Theme/Theme';
import Deals from './Deals';


export default function TabViews({activeIndex,setActiveIndex}) {
  return (
    <div style={{flex:1,display:'flex',flexDirection:'column',overflowY:'hidden'}}>
      <div style={{height:50,display:'flex'}}>
        <div onClick={()=>{
          setActiveIndex(0)
          window.setActiveIndex = 0

        }} style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center',borderColor:activeIndex==0?Theme.FONTCOLOR:'white',borderBottomStyle:'solid'}}>DEALS</div>
        <div onClick={()=>{
          setActiveIndex(1)
          window.setActiveIndex = 1
          
          }} style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center',borderColor:activeIndex==1?Theme.FONTCOLOR:'white',borderBottomStyle:'solid'}}>ORDERS</div>
      </div>
  {activeIndex==0? <Deals />:null}
  {activeIndex==1? <Orders />:null}
    </div>

//     <TabView style={{flex:1,marginTop:-10,height:'100%'}} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
//     <TabPanel style={{flex:1,justifyContent:'center',alignItems:'center',display:'flex',height:'100%'}} header="POSITIONS">
//      <Positions/>
//     </TabPanel>
//     <TabPanel style={{flex:1,justifyContent:'center',alignItems:'center',display:'flex'}}  header="ORDERS">
//     <Orders/>
//     </TabPanel>
//     {/* <TabPanel style={{flex:1,justifyContent:'center',alignItems:'center',display:'flex'}} header="DEALS">
//         <p className="m-0">
//             At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti 
//             quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in
//             culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. 
//             Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
//         </p>
//     </TabPanel> */}
// </TabView>
  )
}
