import React, { useEffect, useState } from 'react'
import TopCardItem from '../../Components/TopCardItem'
import { useWindowSize } from '@react-hook/window-size'
import PositionItemCard from './PositionItemCard'
import { Theme } from '../../Theme/Theme'
import { Sidebar } from 'primereact/sidebar'
import { apiCall } from '../Backend/Api'

export default function Deals() {

  
    const [width, height] = useWindowSize()
    const [visibleBottom, setVisibleBottom] = useState(false);
    const [datefrom, SetDatefrom] = useState(new Date())
    const [dateto, SetDateto] = useState(new Date())
    const [deals, SetDeals] = useState([])
    const [topdata, SetTopdata] = useState([])
    const [symboldata, setSymboldata] = useState([])
    

    useEffect(() => {
      Load_Transaction(); // Initial load
  
      window.Load_Transaction_date=Load_Transaction_date
    }, []);



const Load_Transaction_date =async(a,b)=>{

  let datas ={
    from:a,
    to:b,
  }
  

  const response = await apiCall("/load_transaction",datas)

  if(response){

  
  // alert(JSON.stringify(response.top_detail))
  SetDeals(response?.data)
  window.SetDeals = SetDeals

  localStorage.setItem("dealsdata",JSON.stringify(response.data))
  localStorage.setItem("filterscriptdata",JSON.stringify(response.data))


  SetTopdata(response.top_detail)
  localStorage.setItem("symboldata",JSON.stringify(response.symbol))
  window.setVlaues(new Date())
  }
}
const Load_Transaction=async()=>{
  let datas ={
    from:datefrom,
    to:dateto,
  }
  

  const response = await apiCall("/load_transaction",datas)
  if(response){

  
  // alert(JSON.stringify(response.top_detail))
  SetDeals(response.data)
  window.SetDeals = SetDeals

  localStorage.setItem("dealsdata",JSON.stringify(response.data))
  // localStorage.setItem("filterscriptdata",JSON.stringify(response.data))
  window.setVlaues(new Date())

  

  SetTopdata(response.top_detail)
  localStorage.setItem("symboldata",JSON.stringify(response.symbol))
  }
}

  return (
    <div style={{flex:1,display:'flex',flexDirection:'column',width:width,overflowY:'hidden'}}>
     <div style={{paddingLeft:10,paddingRight:10}}>

      {topdata?.length>0&&topdata?.map((i,t)=>(
 <TopCardItem  name={i.name} key={i.name+i.value} value={i.value}/>
      ))}
       
        {/* <TopCardItem  name={'Deposit'}/>
        <TopCardItem  name={'Rent'}/>
        <TopCardItem  name={'Commision'}/>
        <TopCardItem  name={'Balance'}/> */}
        </div>
        <div style={{borderWidth:0,marginTop:10,borderStyle:'solid',borderBottomWidth:0.5,borderColor:Theme.MEDIUMGRAY}}></div>

     <div style={{flex:1,overflowY:'scroll',display:'flex',flexDirection:'column'}}>
    

      {deals?.length>0 &&deals?.map((i,t)=>(
  <PositionItemCard key={i.id}  data={i}/>
      ))
      }
     

    
      
        {/* <PositionItemCard/>
        <PositionItemCard/> */}
       
     </div>
     {/* <Sidebar visible={visibleBottom} showCloseIcon={false} position="bottom" style={{padding:10,height:'50%'}} onHide={() => setVisibleBottom(false)}>
   <div style={{fontSize:22,fontWeight:'bold',paddingTop:10,display:'flex'}}>{symboldata.symbol}<span style={{marginLeft:10,fontSize:12,flex:1}}>NSE</span> <span style={{marginLeft:5,fontSize:15,color:symboldata.buy_sell == "0"?Theme.FONTCOLOR:Theme.REDCOLOR}}>{symboldata.buy_sell == "0"?"BUY":"SELL"}</span></div>

   <div style={{display:'flex',marginTop:5}}><div style={{flex:1}}>Position Rate</div><span>{symboldata.position_rate}</span></div>
   <div style={{display:'flex',marginTop:5}}><div style={{flex:1}}>Trade Rate</div><span>{symboldata.rateq}</span></div>
    </Sidebar> */}
    </div>
  )
}
