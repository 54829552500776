import { useWindowSize } from '@react-hook/window-size'

import Botttombar from '../../BottomBar/Botttombar'
import TopSideBar from '../../CommonTopBar/TopSideBar'
import { useEffect, useState } from 'react'
import { apiCall } from '../Backend/Api'
import moment from 'moment'

export default function Journal() {
    const [width, height] = useWindowSize()
    const [journals,setJournals] = useState([])

useEffect(()=>{
Load_Journal()
},[])


const Load_Journal =async()=>{
const response = await apiCall('/load_journal')
// alert(JSON.stringify(response))
setJournals(response.data)


}

  return (
    
    
    <div style={{height:height,display:'flex',flexDirection:'column',overflowY:'hidden'}}>
   <TopSideBar name="Journal"/>
      <div style={{flex:1,padding:5,overflowY:'scroll'}}>
{journals?.length>0&&journals?.map((i,t)=>(

<div>{moment(i.date_created).format('DD-MM-YY HH:mm:ss')} : {i.msg}</div>

))}


      </div>
      <Botttombar/>
    </div>
  )
}
