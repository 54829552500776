import React, { useEffect } from "react";
import { useNotification } from "./NotificationContext";
import "./Notification.css";

import { Theme } from "../Theme/Theme";
import { useWindowSize } from "@react-hook/window-size";

const Notification = () => {
  const [width, height] = useWindowSize()
  const { notifications, removeNotification } = useNotification();

  
  useEffect(() => {
    // Check if there is a notification to display
    if (notifications.length > 0) {
      const notification = notifications[0];

      // Remove previous notifications, if any
      if (notifications.length > 1) {
        notifications.slice(1).forEach((n) => removeNotification(n.id));
      }

      // Set a timer to remove the current notification after 2 seconds
      const timer = setTimeout(() => {
        removeNotification(notification.id);
      }, 2000);

      // Clear the timer if the component unmounts or a new notification arrives
      return () => clearTimeout(timer);
    }
  }, [notifications, removeNotification]);
  

  return (
    notifications.map((notification) => (
    <div style={{height:40,background:notification.type == "error" ? Theme.REDCOLOR : Theme.GREENCOLOR ,color:'white',display:"flex",justifyContent:'center',alignItems:'center',fontSize:14,position:'fixed',width:width}}>{notification.message}</div>
  ))
    // <div  style={{width:width}}>
    //   {notifications.map((notification) => (
    //     <div style={{ width: width, background: "white", minHeight: 50, display: "flex", borderRadius: 10 }}>
    //       <div style={{ textAlign: 'left', paddingLeft: 10, fontSize: 14, alignSelf: 'center', fontWeight: 'bold', color: Theme.MEDIUMGRAY, background:  }}>{notification.message}</div>

    //     </div>
        // <div
        //   key={notification.id}
        //   onClick={() => removeNotification(notification.id)}
        //   className={`notification ${notification.type}`}
        // >
        //  
        //   <audio controls>
        //     <source src={notification.soundFile} type="audio/mpeg" />
        //     Your browser does not support the audio element.
        //   </audio>
        // </div>
      // ))}
    // </div>
  );
};

export default Notification;
