import React, { createContext, useState, useContext, useEffect } from "react";
import { useSocket } from "../../Socket/SocketContext";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const { socket } = useSocket();
  // Check localStorage for login status on initialization
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const storedStatus = localStorage.getItem("isLoggedIn");
    if (storedStatus === "true") {
      setIsLoggedIn(true); // Update to true if stored value is true
    }
    // Update localStorage whenever isLoggedIn state changes
    localStorage.setItem("isLoggedIn", isLoggedIn);

    window.setIsLoggedIn = setIsLoggedIn;

    var get_data = JSON.parse(localStorage.getItem("client_details"));

    socket?.emit("login", {
      type: "Client",
      id: get_data?.id,
      admin_id: get_data?.admin_id,
      master_id: get_data?.master_id,
      super_id: get_data?.super_id,
      server: localStorage.getItem("server"),
    });
  }, [isLoggedIn]);

  const login = () => setIsLoggedIn(true);
  const logout = () => setIsLoggedIn(false);

  return (
    <LoginContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
