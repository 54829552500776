import React, { useRef, useState } from 'react'
import MenuIcon from '../../Icons/menu.png'
import PenIcon from '../../Icons/pen.png'
import PlusIcon from '../../Icons/plus.png'
import { useSidebar } from '../../Sidebar/SidebarContext'
import TopCardItem from '../../Components/TopCardItem'
import { Menu } from 'primereact/menu';


import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { Theme } from '../../Theme/Theme'
import { useTradeContext } from './TradeContext'
import { MdOutlineArrowRightAlt } from "react-icons/md"
import { useNavigate } from 'react-router-dom'
import { IoMdMenu } from 'react-icons/io'

export default function TradeTopBar() {
    const {showSidebar} =useSidebar();

    const navigate = useNavigate()
    const menuLeft = useRef(null);
    const [filter,setFilter] = useState("Order")
    const [updown,setUpdown]=useState(0)
const {totalProfitLoss,balance,totalM2m,credit,block,percent}=useTradeContext()


const rearranger = (item)=>{
if(item.label == filter){
    setUpdown(!updown)
    menuLeft.current.toggle(item)
//    alert(JSON.stringify(menuLeft.current))

}else{
    setUpdown(0)
    setFilter(item.label)
}
}

const itemRenderer =(item)=>(
    <div onClick={()=>rearranger(item)} className='p-menuitem-content' style={{background:'white'}}>
    <div className='basefont' style={{height:50,padding:5,paddingLeft:10,paddingRight:5,borderBottomStyle:'solid',borderWidth:item.itemnumnber==0?0:1,borderColor:'gray',fontSize:20,display:'flex',width:120}}>
        <div style={{flex:1}}>{item.label}</div>
        {filter == item.label?
        <div style={{width:30, transform: updown==0? 'rotate(90deg)':'rotate(-90deg)',display:'flex',justifyContent:'center',alignItems:'center'}}><MdOutlineArrowRightAlt size={35} color={Theme.FONTCOLOR}/> </div>:null}
        
        </div>
        </div>

)
    
    const items = [
      {
          items: [
              {
                  label: 'Order',
                  template: itemRenderer

                
              },
              {
                  label: 'Time',
                  template: itemRenderer
                
              },
              {
                  label: 'Symbol',
                  template: itemRenderer
                 
              },
              {
                  label: 'Profit',
                  template: itemRenderer,
                  itemnumnber:0
                 
              }
          ]
      }
  ];
  return (
    <>
    <div style={{height:60,width:'100%',display:'flex'}}>
     <div onClick={showSidebar}  style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}><IoMdMenu size={27}/></div>
     <div style={{flex:1,display:'flex',justifyContent:'center',fontSize:20,flexDirection:'column',alignItems:'flex-start'}}>
      <div style={{fontSize:totalProfitLoss!=0?12:22}}>Trade </div>



      <div style={{fontSize:12,color:Theme.DARKGRAY,paddingLeft:10,position:'fixed',right:10,top:60}}>{JSON.parse(localStorage.getItem('client_details'))?.username?.toUpperCase()} </div>
     
     
     
     
     
     {totalProfitLoss!=0&&
      <div style={{fontSize:17,color:totalProfitLoss>0?Theme.FONTCOLOR:Theme.REDCOLOR}}>{totalProfitLoss}</div>}
      </div>
     {/* <div style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(event) => menuLeft.current.toggle(event)}><HiMiniArrowsUpDown size={27} /></div> */}
     <div onClick={()=>navigate("/buysell")} style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}><BsFileEarmarkPlus size={27}/></div>
    </div>

    <div style={{padding:10,fontWeight:'bold'}}>

<TopCardItem name={'Balance'} value={balance}/>
<TopCardItem name={'Equity'} value={totalM2m}/>
<TopCardItem name={'Margin'} value={credit}/>
<TopCardItem name={'Free Margin'} value={block}/>
<TopCardItem name={'Margin Level (%)'} value={percent+"%"}/>

    </div>
    <Menu model={items} popup ref={menuLeft} style={{width:'auto',paddingBottom:0}} id="popup_menu_right" />
    </>
  )
}
