import { useWindowSize } from '@react-hook/window-size'
import React, { useState } from 'react'
import Botttombar from '../../BottomBar/Botttombar'
import HistoryTopCard from './HistoryTopCard'
import TabViews from './TabViews'

export default function History() {
    const [width, height] = useWindowSize()
    const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div style={{display:'flex',flexDirection:'column',height:height,overflowY:'hidden'}}>
        <HistoryTopCard/>


<TabViews activeIndex={activeIndex} setActiveIndex={setActiveIndex} />
    {/* <div style={{flex:1,overflowY:'scroll'}}>

    </div> */}

<Botttombar active={"HISTORY"}/>
        </div>
  )
}
