import React, { useContext, useEffect, useState } from 'react'
import Botttombar from '../../BottomBar/Botttombar'
import { useWindowSize } from '@react-hook/window-size'
import TopSideBar from '../../CommonTopBar/TopSideBar'
import { LuNewspaper } from 'react-icons/lu'
import { Theme } from '../../Theme/Theme'
import { MdOutlineEmail } from 'react-icons/md'
import { Checkbox } from 'primereact/checkbox'
import { PrimeReactContext } from 'primereact/api';
import { useBuySell } from '../BuySell/BuySellContext'



export default function Setting() {

  const { changeTheme, } = useContext(PrimeReactContext);
  const {   startLoading,
    stopLoading} = useBuySell()

  const[width,height] = useWindowSize()
    const [checked,setChecked] =useState(false)

    const [darkmode,setDarkmode] =useState(false)





useEffect(()=>{
const value =localStorage.getItem('advance') ==0?false:true

const value2 =localStorage.getItem('darktheme') ==1?true:false
setChecked(value)
setDarkmode(value2)


},[])




const onchangeAdvance=(e)=>{

    if(e.checked){
        localStorage.removeItem('advance')
    }else{
        localStorage.setItem('advance',0)
    }
    setChecked(e.checked)

}

const darkmodeselect=(e)=>{
  startLoading()
  setTimeout(() => {
    stopLoading()
  }, 1000);

  if(e.checked){

    localStorage.setItem('darktheme',1)
    changeTheme('lara-light-blue', 'arya-blue', 'themelink', () => {
      console.log('Theme changed successfully!');
  });
    // changeTheme2('arya-blue')
   
  }else{
   
    localStorage.removeItem('darktheme')

    changeTheme( 'arya-blue','lara-light-blue', 'themelink', () => {
    //  alert('Theme changed successfully!');
  });
      // localStorage.setItem('theme','arya-blue')
     
  }


  setDarkmode(e.checked)
  // window.location.reload()

}


  return (
    <div style={{height:height,display:'flex',flexDirection:'column'}}>
     <TopSideBar name="Setting"/>
      <div style={{flex:1,display:'flex',flexDirection:'column',padding:5}}>
      <div style={{borderBottomStyle:'solid',paddingBottom:5,borderWidth:0.5}}>Quotes</div>

      <div style={{display:'flex',paddingTop:10,paddingRight:10}}>
<div style={{flex:1}}>
    <div style={{fontSize:15,color:Theme.DARKGRAY}}>Advanced Mode</div>
    <div style={{fontSize:13,color:Theme.MEDIUMGRAY,fontWeight:'normal'}}>In The advanced mode the quotes window contains spreads,time data, as well as High and Low prices.</div>

</div>




<div style={{width:50,display:'flex',justifyContent:'center'}}>  <Checkbox onChange={onchangeAdvance} checked={checked}></Checkbox></div>
      </div>



      <div style={{display:'flex',paddingTop:10,paddingRight:10}}>
<div style={{flex:1}}>
    <div style={{fontSize:15,color:Theme.DARKGRAY}}>Dark Mode</div>
    <div style={{fontSize:13,color:Theme.MEDIUMGRAY,fontWeight:'normal'}}>In The advanced mode the quotes window contains spreads,time data, as well as High and Low prices.</div>

</div>




<div style={{width:50,display:'flex',justifyContent:'center'}}>  <Checkbox onChange={darkmodeselect} checked={darkmode}></Checkbox></div>
      </div>

      </div>
      <Botttombar/>
    </div>
  )
}
