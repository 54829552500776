import React from 'react'
import MenuIcon from '../../Icons/menu.png'
import PenIcon from '../../Icons/pen.png'
import PlusIcon from '../../Icons/plus.png'
import { useSidebar } from '../../Sidebar/SidebarContext'
import { useNavigate } from 'react-router-dom'
import { IoMdMenu } from 'react-icons/io'

export default function MessageTopCard() {
    const {showSidebar} =useSidebar();
    const navigate = useNavigate()
  return (
    <div  style={{height:60,width:'100%',display:'flex'}}>
     <div onClick={showSidebar} style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}><IoMdMenu size={27}/></div>
     <div style={{flex:1,display:'flex',alignItems:'center',fontSize:20}}>Messages</div>
    
     
    </div>
  )
}
