import { useWindowSize } from "@react-hook/window-size";
import React from "react";

import { Theme } from "../../Theme/Theme";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import TopSideBar from "../../CommonTopBar/TopSideBar";
import Botttombar from "../../BottomBar/Botttombar";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Image } from "primereact/image";

export default function ManageAccount() {
  const navigate = useNavigate();

  const handleLogout = () => {
    const yesno = window.confirm("ARE YOU SURE YOU WANT TO LOG OUT?");
    if (yesno) {
      localStorage.clear();
      window.setIsLoggedIn(false);
      // window.LOGOUTSTATE(new Date())
      // localStorage.removeItem("")
      // setTimeout(() => {
      //   navigate("/login")
      // }, 200);
    }
  };

  const [width, height] = useWindowSize();
  return (
    <div style={{ height: height, display: "flex", flexDirection: "column" }}>
      <TopSideBar name="Accounts" />
      <div style={{ flex: 1 }}>
        <div style={{ padding: 5 }}>
          <div
            style={{
              background: "rgba(0,0,0,0.04)",
              padding: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: 20,
              position: "relative",
            }}
          >
            <div
              style={{
                height: 100,
                width: 100,
                justifySelf: "center",
                alignSelf: "center",
              }}
            >
              <Image src={require("../../Theme/logo.png")} width="100" />
            </div>
            <div style={{ fontWeight: "bold", fontSize: 18 }}>
              Delivery Finance Capitals
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 14,
                marginTop: 5,
                color: Theme.FONTCOLOR,
              }}
            >
              Delivery Finance Ltd.
            </div>

            <div
              style={{
                fontSize: 18,
                marginTop: 20,
                textTransform: "uppercase",
              }}
            >
              {JSON.parse(localStorage.getItem("client_details")).username}
            </div>
            <div
              style={{
                fontSize: 14,
                textTransform: "uppercase",
                color: Theme.DARKGRAY,
              }}
            >
              Access Point UK 1,Hedge
            </div>

            <div
              style={{
                fontSize: 22,
                textTransform: "uppercase",
                marginTop: 20,
              }}
            >
              CREDITS
            </div>

            <div style={{ position: "absolute", top: 0, right: 0 }}>
              <Button
                onClick={handleLogout}
                icon={<RiLogoutCircleRLine size={25} />}
                rounded
                text
                severity="secondary"
                aria-label="Bookmark"
              />
            </div>
          </div>
        </div>
      </div>
      <Botttombar />
    </div>
  );
}
