import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import debounce from 'lodash.debounce'; // Ensure lodash.debounce is installed

// Styled components
const CardContainer = styled.div`
  position: relative;
  border-bottom: 1px solid #ccc;
  padding: 20px;
  padding-bottom: 15px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    transform: scale(0);
    pointer-events: none;
    animation: ripple-animation 0.6s linear;
    z-index: 1;
  }

  @keyframes ripple-animation {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

const Name = styled.div`
  /* Additional styles if needed */
`;

const Detail = styled.div`
  font-size: 12px;
  color: #888;
`;

const Highlight = styled.span`
  color: #007bff; /* Replace with Theme.FONTCOLOR */
  font-weight: bold;
`;

// Function to highlight matching text
const highlightText = (text, highlight) => {
  if (!highlight?.trim()) return text;

  const regex = new RegExp(`(${highlight})`, 'gi');
  const parts = text?.split(regex);

  return parts.map((part, index) =>
    regex.test(part) ? (
      <Highlight key={index}>
        {part}
      </Highlight>
    ) : (
      part
    )
  );
};

// Debounced function to handle click events
const handleClick = debounce((onClick) => {
  onClick();
}, 200); // Adjust debounce delay as needed

const AddCard = ({ search, name, detail, onClick }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const touchStartTime = useRef(null);
  const isScrolling = useRef(false);

  const handleRipple = useCallback((e) => {
    if (isDisabled || isScrolling.current) return;

    if (e.type === 'click' || (e.type === 'touchend' && !isScrolling.current)) {
      setIsDisabled(true);
      handleClick(() => {
        onClick();
        setIsDisabled(false); // Re-enable after the click action is processed
      });

      // Create ripple element
      const ripple = document.createElement('div');
      ripple.classList.add('ripple');

      // Determine size and position of ripple
      const size = Math.max(e.currentTarget.clientWidth, e.currentTarget.clientHeight);
      const { left, top } = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - left - size / 2;
      const y = e.clientY - top - size / 2;

      // Set ripple size and position
      ripple.style.width = ripple.style.height = `${size}px`;
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;

      // Append ripple to the container
      e.currentTarget.appendChild(ripple);

      // Remove ripple after animation
      setTimeout(() => {
        ripple.remove();
      }, 600); // Match this time with animation duration
    }
  }, [isDisabled]);

  const handleTouchStart = (e) => {
    touchStartTime.current = Date.now();
    isScrolling.current = false;
  };

  const handleTouchMove = () => {
    isScrolling.current = true;
  };

  const handleTouchEnd = (e) => {
    const touchDuration = Date.now() - touchStartTime.current;
    
    if (touchDuration < 500 && !isScrolling.current) { // Adjust 500ms for long press threshold
      handleRipple(e);
    }
    touchStartTime.current = null;
  };

  return (
    <CardContainer
      onClick={handleRipple}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      disabled={isDisabled}
    >
      <div>{highlightText(name, search)}</div>
      <div style={{ fontSize: 12, color: '#888' }}>{highlightText(detail, search)}</div>
    </CardContainer>
  );
};

export default AddCard;
