const api_link = "https://trade.dfcapitals.com";
const access_key =
  "123";


export async function TradeApi(path, data) {
  try {

    let access = {
      sv: localStorage.getItem("server"),
      jwt: localStorage.getItem("token"),
      id: localStorage.getItem("client"),
    };
    const response = await fetch(api_link + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        device_info:"CHROME",
        "user-token":localStorage.getItem("token"),
        "id":localStorage.getItem("client"),
        "x-access-token":access_key
      },
      body: JSON.stringify({
       ...data,...access ,
    
      }),
    });

    const jsonResponse = await response.json();
    // console.log(jsonResponse);
    // alert(JSON.stringify(jsonResponse))
    return jsonResponse;
    if (jsonResponse.error === "False") {
 
    } else{
      window.addNotification(jsonResponse.message,'error')
    }
  } catch (error) {
    console.error("API call failed:", error); // Log any errors
    // throw error; // Rethrow the error to be handled by the calling function
  }
}
// }
