import React, { useMemo, useEffect, useState } from 'react';
import { Theme } from '../../Theme/Theme';
import BidAskCard from './BidAskCard';
import { useSocket } from '../../Socket/SocketContext';
import moment from 'moment';
import { BiCollapseVertical } from "react-icons/bi";
import { useTradeContext } from '../Trade/TradeContext';
import { BiSolidLeftArrow } from "react-icons/bi";

export default function WatchlistCard({ openwindow, item }) {
  const [pfl, setPfl] = useState(0);
  const { subscribeToSymbol, data,socket } = useSocket();
  const { GetProfitLoss } = useTradeContext();

  const [scriptData,setScriptdata] = useState({})

  const [values ,setVlaues] = useState(new Date())

  // Get script ID from the item
  const scriptId = item.script_id;


  const handleTrade = (data) => {
    setScriptdata((prevData) => ({ ...prevData, ...data }));
  };


const handleBidask = (data) => {
  setScriptdata((prevData) => ({
    ...prevData,
    Bid: data.Bid,
    Ask: data.Ask,
  }));
};


  // const scriptData = data[scriptId] || {};


useEffect(()=>{

  socket?.on(`trade${scriptId}`, handleTrade);
  socket?.on(`bidask${scriptId}`, handleBidask);


  socket?.emit("giverate",scriptId)

  return () => {
    socket?.off(`trade${scriptId}`, handleTrade);
    socket?.off(`bidask${scriptId}`, handleBidask);
  };
},[socket])




  useEffect(() => {
    const pfls = GetProfitLoss(scriptId,scriptData);
    setPfl(pfls);
  }, [scriptId, scriptData, GetProfitLoss]);

  const chang = useMemo(() => {
    const changeValue = (scriptData.LTP || 0) - (scriptData.Previous_Close || 0);
    return isNaN(changeValue) ? 0 : changeValue;
  }, [scriptData.LTP, scriptData.Previous_Close]);



  const percent = useMemo(() => {
    if (scriptData.Previous_Close) {
      const percentValue = (chang / scriptData.Previous_Close) * 100;
      return isNaN(percentValue) ? 0 : percentValue;
    }
    return 0;
  }, [chang, scriptData.Previous_Close]);

  const formattedTimestamp = useMemo(() => moment(scriptData.Timestamp).format("HH:mm:ss"), [scriptData.Timestamp]);

  return (
    <div style={{ padding: 10, display: 'flex', position: 'relative' }} onClick={openwindow}>
      {pfl !== 0 && (
        <div style={{
          position: 'absolute',
          height: 15,
          width: 15,
          top: -5,
          left: -10,
          transform: 'rotate(45deg)',
          transformOrigin: 'center',
        }}>
          <BiSolidLeftArrow color={pfl < 0 ? Theme.REDCOLOR : Theme.FONTCOLOR} size={20} />
        </div>
      )}

      <div style={{ flex: 3 }}>
        <div style={{ display: 'flex' }}>
          <div style={{ fontSize: 14, color: 'gray' }}>{chang.toFixed(2)}</div>
          <div style={{ marginLeft: 10, fontSize: 14, color: Theme.FONTCOLOR }}>{parseFloat(percent).toFixed(2)}%</div>
        </div>
        <div style={{ fontSize: 16 }}>{item.name}</div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ fontSize: 12, color: 'gray' }}>{formattedTimestamp}</div>
          <div style={{ marginLeft: 5, color: 'gray', fontSize: 14, height: 12 }}></div>
          <div style={{ marginLeft: 5, fontSize: 14, color: Theme.FONTCOLOR }}>{scriptData?.LTP}</div>
        </div>
      </div>

      <BidAskCard hl={"L"} bid={scriptData?.Bid} high={scriptData?.Low} />
      <BidAskCard hl={"H"} bid={scriptData?.Ask} high={scriptData?.High} />
    </div>
  );
}
