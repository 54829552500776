import React, { useEffect, useState } from "react";
import { Theme } from "../../Theme/Theme";
import { apiCall } from "../Backend/Api";
import PendingCard from "../History/PendingCard";
import { Sidebar } from "primereact/sidebar";
import PendingBottomBar from "../History/PendingBottomBar";

export default function PositionPending() {
  const [datefrom, SetDatefrom] = useState(new Date());
  const [dateto, SetDateto] = useState(new Date());
  const [deals, SetDeals] = useState([]);
  const [visibleBottom, setVisibleBottom] = useState(false);
  const [symboldata, setSymboldata] = useState([]);
  useEffect(() => {
    Load_Transaction();
  }, []);

  const Load_Transaction = async () => {
    let datas = {
      from: datefrom,
      to: dateto,
    };

    // let response;
    // if (notop) {
    //   response = await apiCall("/load_position_pending", datas);
    // } else {
    let response = await apiCall("/load_position_pending", datas);
    // }

    if (response) {
      //   alert(JSON.stringify(response));
      SetDeals(response.data);
    }
    // alert(JSON.stringify(response.symbol))
  };

  return (
    deals?.length > 0 && (
      <div>
        <div
          style={{
            padding: 5,
            background: Theme.LIGHTGRAY,
            borderStyle: "solid",
            borderWidth: 0,
            borderTopWidth: 0.5,
            borderBottomWidth: 0.5,
            borderColor: Theme.MEDIUMGRAY,
            display: "flex",
            color: Theme.DARKGRAY,
          }}
        >
          <div style={{ flex: 1 }}>Orders</div>
        </div>

        {deals?.length > 0 &&
          deals.map((i, t) => (
            <PendingCard
              key={i.id}
              openwindow={() => {
                if (i.status == 0) {
                  setVisibleBottom(true);
                  setSymboldata(i);
                }
              }}
              data={i}
            />
          ))}

        <Sidebar
          visible={visibleBottom}
          showCloseIcon={false}
          position="bottom"
          style={{ padding: 10, height: "50%" }}
          onHide={() => setVisibleBottom(false)}
        >
          <PendingBottomBar
            symboldata={symboldata}
            closesheet={() => {
              setVisibleBottom(false);
              Load_Transaction();
            }}
          />
        </Sidebar>
      </div>
    )
  );
}
