

import React, { useEffect, useRef, useState } from 'react'


import { useSidebar } from '../../Sidebar/SidebarContext'



import { Menu } from 'primereact/menu';


import { HiMiniArrowsUpDown } from "react-icons/hi2";

import { Theme } from '../../Theme/Theme'

import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import moment from 'moment';
import { IoMdMenu } from 'react-icons/io';
import { MdCalendarMonth } from 'react-icons/md';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';


export default function HistoryTopCard() {

    const [typesort,setTypesort] =useState("DATE")
    const [updown,setUpdown] =useState(true)
    const [dates, setDates] = useState(null);
    const [tabname ,setTablname] =useState("All Symbols")
    const [backdrop,setBackdrop] =useState(false)

    const [totalpfl ,setTotalpfl] =useState(0)

    const [value,setVlaues] = useState(5)


    const [showCalendar, setShowCalendar] = useState(false);

    


  const  DateFIlter =(e)=>{

    if(e==="TODAY"){
        window.SetDeals([])
        window.Load_Transaction_date(new Date(),new Date())
    }
    if(e==="7day"){

        window.SetDeals([])
        window.Load_Transaction_date(moment().subtract(7, 'days'), new Date());
    }

    if (e === "month") {
        window.SetDeals([]);
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        window.Load_Transaction_date(startOfMonth.toDate(), endOfMonth.toDate());
    }

    if (e === "custom") {
    //    calanderref.current.focus()
    setBackdrop(true)
    setDates([new Date(),new Date()])
    }
    
  }

  const handleCustomDateChange = () => {
    setBackdrop(false)
    const [startDate, endDate] = dates;
 if(startDate ==null || endDate ==null){
    return false
 }else if (startDate && endDate) {
        window.SetDeals([]);
        window.Load_Transaction_date(startDate, endDate);
        setShowCalendar(false); // Close the calendar after selection
    }
};

    const dates_menu =[
        {
            label:'TODAY',
            command: () => {
                DateFIlter("TODAY")

            }
        },
        {
            label:'Last 7 Days',
            command: () => {
                DateFIlter("7day")

            }
        },
        {
            label:'This Month',
            command: () => {
                DateFIlter("month")

            }
        },
        {
            label:'Custom',
            command: () => {
                DateFIlter("custom")

            }
        },



    ]

    const items = window.setActiveIndex ==0?[


        {
            label:"PROFIT",
            command: () => {
                SORT_TRADE("PROFIT")
            }
        }
        ,
        {
            label:"SYMBOL",
            command: () => {
                SORT_TRADE("SYMBOL")
            }
        }
        ,
        {
            label:"DATE",
            command: () => {
                SORT_TRADE("DATE",)
            }
        }
    ]:[

        {
            label:"SYMBOL",
            command: () => {
                SORT_TRADE("SYMBOL")
            }
        }
        ,
        {
            label:"DATE",
            command: () => {
                SORT_TRADE("DATE",)
            }
        }
    ]
    const {showSidebar} =useSidebar();
    const menuLeft = useRef(null);
    const menuRIGHT = useRef(null);
    const menuDATE = useRef(null);
    const calanderref = useRef(null);

    const [filter,setFilter] =useState([])
    window.setVlaues =setVlaues


    const SORT_TRADE=(e)=>{
        const data = JSON.parse(localStorage.getItem("dealsdata"))

        if(typesort == e){
         
          
            setUpdown(prev => !prev);
            
        }else{
            setTypesort(e)
            setUpdown(true)
        }

        
        if(e =="DATE"){
            window.SetDeals(data)

            if (updown) {
                data.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
            } else {
                data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
            }
            window.SetDeals(data)
        }else if (e =="SYMBOL"){
            if(updown){
                data.sort((a, b) => a.symbol.localeCompare(b.symbol));
            }else{
                data.sort((a, b) => b.symbol.localeCompare(a.symbol));
            }
           
            window.SetDeals(data)
        }else if (e === "PROFIT") {
            if(updown){
                data.sort((a, b) => a.profit_loss - b.profit_loss);
            }else{
                data.sort((a, b) => b.profit_loss - a.profit_loss);
            }
            // Sort by profit_loss (assuming it's a number)
           
            window.SetDeals(data) // Ascending order
        }

    }

    const filter_trade =(i)=>{
       
        const data = JSON.parse(localStorage.getItem("dealsdata"))

        if(i =="ALL"){
           
            setTablname("All Symbols")
            setTotalpfl(0)
            // localStorage.setItem('filterscriptdata',localStorage.getItem("dealsdata"))
            window.SetDeals(data)

        }else{
            setTablname(i)
            
            const filterdata =data.filter(x=>x.symbol ===i)

                if(filterdata){
                    var pfl = 0
                    filterdata.map((y)=>{
                        pfl =parseFloat(pfl)+parseFloat(y.profit_loss)
                    })
                    setTotalpfl(pfl)
                }

            window.SetDeals(filterdata)
         
            // localStorage.setItem('filterscriptdata',JSON.stringify(filterdata))
        }

      

    }

    useEffect(()=>{
        setTablname("All Symbols")
      
        var newvalues = localStorage.getItem("symboldata") ?JSON.parse(localStorage.getItem("symboldata")):[]

        var newdata = []
        

        newdata.push({
            label:"ALL SYMBOL",
            id:"0",
             command: () => {
                filter_trade("ALL")
            }
              
            

        })
        newvalues?.map((i,t)=>{
            newdata.push({
                label:i.symbol,
                id:i.script_id,
                command: () => {
                    filter_trade(i.symbol)
                  
                }

            })
        })

        setFilter(newdata)

    },[value])

   
    

  return (
    <>
    <div style={{height:60,width:'100%',display:'flex'}}>


{backdrop&&
<div style={{position:'fixed',height:'100%',width:'100%',background:'rgba(0,0,0,0.3)',display:'flex',justifyContent:'center'}}>
 <Calendar  style={{position:'fixed',justifySelf:'center',alignSelf:'center',top:'30%'}} ref={calanderref} value={dates} onChange={(e) => {setDates(e.value)
      
    }} selectionMode="range"   inline readOnlyInput hideOnRangeSelection 
    
    footerTemplate={()=>
        <div style={{display:'flex',justifyContent:'end'}}>
       
        <Button text onClick={()=>setBackdrop(false)} >Cancel</Button>
        <Button onClick={handleCustomDateChange} text >Ok</Button>
        </div>
      }
    /> 
 </div>
 }


    



     <div onClick={showSidebar}  style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}><IoMdMenu size={27}/></div>
     <div style={{flex:1,display:'flex',justifyContent:'center',fontSize:20,flexDirection:'column',alignItems:'flex-start'}}>
      <div style={{fontSize:12}}>HISTORY</div>
     
      <div style={{fontSize:16,color:Theme.MEDIUMGRAY}}>{tabname}</div>
     {tabname !="All Symbols"&& <div style={{fontSize:13,color:totalpfl<0?Theme.REDCOLOR:Theme.FONTCOLOR}}>{parseFloat(totalpfl).toFixed(2)}</div>}
      </div>


      <div style={{width:55,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(event) => menuLeft.current.toggle(event)}><HiOutlineCurrencyRupee size={30} /></div>

     <div style={{width:55,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(event) => menuRIGHT.current.toggle(event)}><HiMiniArrowsUpDown size={27} /></div>
     <div style={{width:55,display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(event) => menuDATE.current.toggle(event)}><MdCalendarMonth size={27}/></div>
    </div>

    <div style={{padding:10,fontWeight:'bold'}}>
        


    </div>
    <Menu model={filter}  popup ref={menuLeft} id="popup_menu_left" />
    <Menu model={items}  popup ref={menuRIGHT} id="popup_menu_left" />
    <Menu model={dates_menu}  popup ref={menuDATE} id="popup_menu_left" />
    </>
  )
}
