import React from 'react'
import { Theme } from '../Theme/Theme'

export default function BottombarItem({Icon,name,active,onClick,value}) {
  return (
    <div onClick={onClick} style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',position:'relative'}}>
        <Icon size={25} color={active?Theme.FONTCOLOR:'unset'} />
        <div style={{marginTop:5,fontSize:13,color:active?Theme.FONTCOLOR:'unset'}}>{name}</div>
        {value>0 &&
        <div style={{position:'absolute',height:20,width:20,background:Theme.FONTCOLOR,top:0,alignSelf:'center',marginLeft:30,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:50,color:'white'}}>{value}</div>}
    </div>
  )
}
