import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [data, setData] = useState({});
  const [subscribedSymbols, setSubscribedSymbols] = useState(new Set());

  useEffect(() => {
    const newSocket = io('https://emit.dfcapitals.com'); // Replace with your server URL
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to server');
    });
    
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const subscribeToSymbol = (scriptId) => {
    return false
   
    if (!subscribedSymbols.has(scriptId)) {

      setSubscribedSymbols((prev) => new Set(prev).add(scriptId));
    }

      // Event handlers for trade data
      const handleTradeData = (tradeData) => {
        console.log(tradeData,"tradeData")
        setData((prevData) => ({
          ...prevData,
          [tradeData.Symbol_ID]: tradeData,
        }));
      };

      const handleBidAskData = (bidaskData) => {
        console.log(bidaskData,"bidaskData")
        setData((prevData) => ({
          ...prevData,
          [bidaskData.Symbol_ID]: {
            ...prevData[scriptId],
            Bid: bidaskData.Bid,
            Ask: bidaskData.Ask,
            Timestamp: bidaskData.Timestamp,
          },
        }));
      };

      // Subscribe to socket events
      socket?.on(`trade${scriptId}`, handleTradeData);
      socket?.on(`bidask${scriptId}`, handleBidAskData);

      // Request the rate for the subscribed symbol
    
      socket?.emit('giverate', scriptId);
  

      // Cleanup function to remove listeners when unsubscribing
      return () => {
        socket?.off(`trade${scriptId}`, handleTradeData);
        socket?.off(`bidask${scriptId}`, handleBidAskData);
      };
    
  };

  return (
    <SocketContext.Provider value={{ data, subscribeToSymbol, socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
