import React, { useState } from 'react'
import { Theme } from '../../Theme/Theme'
import { useMessages } from './MessageContext'

export default function MessageItem({data}) {
    const [stat,setStat] = useState(0)

    const {readMessage} = useMessages()


    const ClickHandler =()=>{
        setStat(1)
        if(data.unread ==1){
            readMessage(data.id)
        }
    }
  return (
    <div onClick={ClickHandler} style={{flex:1,padding:5,marginBottom:5,position:'relative'}}>
        <div style={{color:Theme.FONTCOLOR}}>{data.header}</div>
       <div style={{width:stat==0? 200:'unset',
  overflow: stat==0? 'hidden':'unset', // Hide overflowing text
  textOverflow: stat==0? 'ellipsis':'unset', // Add ellipsis for overflow text
  whiteSpace:stat==0? 'nowrap':'unset' ,fontSize:12}}>{data.content} </div>
  {data.unread ==1&&<div style={{position:'absolute',height:10,width:10,right:10,alignSelf:'center',background:Theme.FONTCOLOR,top:20,borderRadius:20}}></div>}
    </div>
  )
}
