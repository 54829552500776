import React from 'react'
import BottombarItem from './BottombarItem'




import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { BiCandles } from "react-icons/bi";
import { SlGraph } from "react-icons/sl";
import { RiHistoryFill } from "react-icons/ri";
import { PiChatsBold } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';
import { useMessages } from '../Screens/Message/MessageContext';


export default function Botttombar({active}) {
  const navigate = useNavigate()
  const {messages} =useMessages()
  const count =messages?.filter(x=>x.unread==1)
  return (
    <div style={{height:60,display:'flex',zIndex:99,borderWidth:0,borderStyle:'solid',borderTopWidth:0.5,borderTopColor:'#f4f4f4',zIndex:99}}>
      <BottombarItem onClick={()=>active!=="HOME"?navigate('/home'):null} active={active=='HOME'}  Icon={HiMiniArrowsUpDown} name={'Quotes'}/>
      <BottombarItem onClick={()=>active!=="CHART"?navigate('/chart'):null} active={active=='CHART'} Icon={BiCandles} name={'Chart'}/>
      <BottombarItem  onClick={()=>active!=="TRADE"?navigate('/trade'):null}  active={active=='TRADE'} Icon={SlGraph} name={'Trade'}/>
      <BottombarItem onClick={()=>active!=="HISTORY"?navigate('/history'):null}   active={active=='HISTORY'} Icon={RiHistoryFill} name={'History'}/>
      <BottombarItem value={count?.length} onClick={()=>active!=="MESSAGE"?navigate('/message'):null}  active={active=='MESSAGE'} Icon={PiChatsBold} name={'Messages'}/>
      
      {/* 
     
     
      */}
    </div>
  )
}
