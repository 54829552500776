import React from 'react'
import Botttombar from '../../BottomBar/Botttombar'
import { useWindowSize } from '@react-hook/window-size'
import TopSideBar from '../../CommonTopBar/TopSideBar'
import { LuNewspaper } from 'react-icons/lu'
import { Theme } from '../../Theme/Theme'


export default function News() {
  const[width,height] = useWindowSize()
  return (
    <div style={{height:height,display:'flex',flexDirection:'column'}}>
     <TopSideBar name="News"/>
      <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <LuNewspaper size={75} color={Theme.MEDIUMGRAY}/>
        <div style={{color:Theme.MEDIUMGRAY,marginTop:30}}>No News</div>
      </div>
      <Botttombar/>
    </div>
  )
}
