import React from 'react'
import Botttombar from '../../BottomBar/Botttombar'
import { useWindowSize } from '@react-hook/window-size'
import TopSideBar from '../../CommonTopBar/TopSideBar'

export default function Chart() {
    const[width,height] = useWindowSize()
  return (
    <div style={{display:'flex',flexDirection:'column',height:height}}>
       <TopSideBar name="Chart" />
        <div  style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center'}}>
            CHART COMING SOON
        </div>
        <Botttombar active={"CHART"}/>
    </div>
  )
}
