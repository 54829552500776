import React from 'react'
import { Theme } from '../Theme/Theme';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from './SidebarContext';

export default function SideBaritem({Icon,size,name,selected,path ="/"}) {
  const navigate = useNavigate()
  const {hideSidebar} = useSidebar()

  const onClick =()=>{
   navigate(path)
   hideSidebar()

  }

  return (
    <div onClick={onClick} style={{paddingTop:10,paddingLeft:20,display:'flex',background:selected&&Theme.LIGHTGRAY,paddingBottom:7,justifyContent:'center',alignItems:'center'}}>
        <div style={{width:50}}>{Icon&&<Icon size={size}/>}</div>
        <div style={{flex:1,fontWeight:'bold',fontSize:14}}>{name}</div>
    </div>
  )
}
