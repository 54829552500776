import React, { useEffect, useState } from "react";

import HomeTopBar from "./HomeTopBar";
import { useWindowSize } from "@react-hook/window-size";
import Botttombar from "../../BottomBar/Botttombar";
import WatchlistCard from "./WatchlistCard";
import { Sidebar } from "primereact/sidebar";
import { apiCall } from "../Backend/Api";
import { useWatchlist } from "./WatchlistContext";
import { useTradeContext } from "../Trade/TradeContext";
import SimpleMode from "./SimpleMode";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../Theme/Theme";

export default function Home() {
  const [width, height] = useWindowSize();
  const [visibleBottom, setVisibleBottom] = useState(false);
  const navigate = useNavigate();
  const [datas, setdatas] = useState("");
  useEffect(() => {}, [datas]);

  const [scriptdata, setScriptdata] = useState([]);

  const clientData = {
    screenWidth: window,
    language: navigator.language,
    userAgent: navigator.userAgent,
  };

  // console.log(clientData)

  const { watchlist, addToWatchlist, removeFromWatchlist, loading } =
    useWatchlist();
  // const{loadTrades} =useTradeContext()
  // useEffect(()=>{
  //   loadTrades()
  // },[])

  const navigateBuysell = () => {
    localStorage.setItem("script", JSON.stringify(scriptdata));
    navigate("/buysell");
  };

  const NavigateProperties = () => {
    localStorage.setItem("script", JSON.stringify(scriptdata));
    navigate("/properties");
  };

  const NavigateStat = () => {
    localStorage.setItem("script", JSON.stringify(scriptdata));
    navigate("/statatics");
  };

  const Handleviewmode = (e) => {
    if (e == "0") {
      localStorage.setItem("advance", 0);
    } else {
      localStorage.removeItem("advance");
    }
    setdatas(new Date());
    setVisibleBottom(false);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: height }}>
      <HomeTopBar />
      <div style={{ flex: 1, overflowY: "scroll" }}>
        {watchlist &&
          watchlist.map((i, t) =>
            localStorage.getItem("advance") == 0 ? (
              <SimpleMode
                openwindow={(a) => {
                  setVisibleBottom(true);
                  setScriptdata(i);
                }}
                item={i}
                key={i.id}
              />
            ) : (
              <WatchlistCard
                openwindow={(a) => {
                  setVisibleBottom(true);
                  setScriptdata(i);
                }}
                item={i}
                key={i.id}
              />
            )
          )}
      </div>
      <Botttombar active={"HOME"} />

      <Sidebar
        visible={visibleBottom}
        showCloseIcon={false}
        position="bottom"
        style={{ padding: 0, height: 300 }}
        onHide={() => setVisibleBottom(false)}
      >
        <div style={{ fontSize: 16, fontWeight: "bold", padding: 10 }}>
          {scriptdata.name}
          <span style={{ marginLeft: 10, fontSize: 12 }}>NSE</span>
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            borderWidth: 0.5,
            paddingLeft: 20,
            fontSize: 16,
          }}
          onClick={navigateBuysell}
        >
          New Order
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            borderWidth: 0.5,
            paddingLeft: 20,
            fontSize: 16,
            color: Theme.MEDIUMGRAY,
          }}
        >
          CHART
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            borderWidth: 0.5,
            paddingLeft: 20,
            fontSize: 16,
            color: "black",
          }}
          onClick={NavigateProperties}
        >
          Properties
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            borderWidth: 0.5,
            paddingLeft: 20,
            fontSize: 16,
            color: "black",
          }}
          onClick={NavigateStat}
        >
          Market Statistics
        </div>
        {localStorage.getItem("advance") == 0 ? (
          <div
            style={{
              padding: 10,
              display: "flex",
              borderWidth: 0.5,
              paddingLeft: 20,
              fontSize: 16,
            }}
            onClick={() => Handleviewmode("1")}
          >
            Advanced View
          </div>
        ) : (
          <div
            style={{
              padding: 10,
              display: "flex",
              borderWidth: 0.5,
              paddingLeft: 20,
              fontSize: 16,
            }}
            onClick={() => Handleviewmode("0")}
          >
            Simple View
          </div>
        )}
      </Sidebar>
    </div>
  );
}
