import React, { useEffect } from 'react'
import Botttombar from '../../BottomBar/Botttombar'
import { useWindowSize } from '@react-hook/window-size'
import MessageTopCard from './MessageTopCard'
import { useMessages } from './MessageContext'
import MessageItem from './MessageItem'

export default function Message() {
    const[width,height] = useWindowSize()
    const {loadMessage,messages} = useMessages()
    useEffect(()=>{
      loadMessage()

    
  }  ,[])
   return (
    <div style={{display:'flex',flexDirection:'column',height:height}}>
      <MessageTopCard/>
        <div  style={{flex:1,overflowY:'scroll'}}>

           {messages.length && messages.map((i,t)=>(
            <MessageItem data={i} key={i.id}/>
           ))}
        </div>
        <Botttombar value={2} active={"MESSAGE"}/>
    </div>
  )
}
