import React from 'react'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '../Icons/menu.png'
import PenIcon from '../Icons/pen.png'
import PlusIcon from '../Icons/plus.png'
import { IoArrowBackOutline } from "react-icons/io5";

export default function CommonTopBar({heading,Button1,Button2,Onclick1={},Onclick2={}}) {
 
    const navigate = useNavigate()
    return (
      <div  style={{height:60,width:'100%',display:'flex'}}>
       <div onClick={()=>navigate(-1)} style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}>
       <IoArrowBackOutline size={25}/>
        </div>

        
       <div style={{flex:1,display:'flex',alignItems:'center',fontSize:20}}>{heading}</div>
       {Button1&& <div onClick={Onclick1}  style={{width:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
       <Button1 size={25}/>
        </div>}
        

        {Button2&& <div onClick={Onclick2} style={{width:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
       <Button2 size={25}/>
        </div>}
       {/* <div style={{width:60,display:'flex',justifyContent:'center',alignItems:'center'}}><img src={PenIcon} style={{height:25,width:25}}/></div> */}
      </div>
  )
}
