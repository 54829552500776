import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@react-hook/window-size';
import CommonTopBar from '../../CommonTopBar/CommonTopBar';
import { FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import EditCard from './EditCard';
import { useWatchlist } from '../Home/WatchlistContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { OrderList } from 'primereact/orderlist';
import DeleteCard from './DeleteCard';

export default function EditSymbol() {
  const [width, height] = useWindowSize();
  const navigate = useNavigate();
  const [deleteList, setDeleteList] = useState([]);
  const [deletes,setDeletes] = useState(false)
  const { watchlist, setWatchlist, loading,removeFromWatchlist } = useWatchlist();
  const location = useLocation();



  const handleCheckboxChange = (id, checked) => {
    setDeleteList(prevList => 
      checked 
      ? [...prevList, id] 
      : prevList.filter(item => item !== id)
    );
  };

  const HandleDeleteButton=()=>{
    if(!deletes){
      setDeletes(true)
    }else{
     
      if (deleteList.length > 0) {
        removeFromWatchlist(deleteList);
        setDeleteList([]); 
        setDeletes(false)
        // Reset delete list after deletion
      }

    }
  }
 

  return (
    <div style={{ height, display: 'flex', flexDirection: 'column' }}>
      <CommonTopBar heading="Selected Symbol" Button1={FaPlus} Onclick2={HandleDeleteButton} Onclick1={() => navigate('/addsymbol')} Button2={MdDelete} />
      <div style={{ flex: 1, overflowY: 'scroll', paddingTop: 20 }}>

      {!deletes&&watchlist&&watchlist.map((i,t)=>(
      <EditCard
       data={i}
      key={i.script_id}      
    
      />
    ))}

  
    {deletes&&watchlist&&watchlist.map((i,t)=>(
      <DeleteCard data={i}
      key={i.script_id}      
      onStatusChange={handleCheckboxChange}
      />
    ))}
    </div>
    </div>
  );
}
