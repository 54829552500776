import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { RxDragHandleHorizontal } from 'react-icons/rx';

export default function DeleteCard({ data, onStatusChange }) {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.checked);
    onStatusChange(data.script_id, e.checked); // Notify parent component about the status change
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        paddingBottom: 15,
        borderWidth: 1,
        borderColor: '#ccc',
        borderBottomStyle: 'solid',
        backgroundColor: 'white',
      }}
    >
   
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div>{data?.name}</div>
        <div style={{ fontSize: 12, color: '#888' }}>{data?.detail}</div>
      </div>
      <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        style={{ marginRight: 10 }}
      />
      </div>
    </div>
  );
}
