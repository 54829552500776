// src/AppRoutes.js
import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import History from "../History/History";
import Home from "../Home/Home";
import Trade from "../Trade/Trade";
import Login from "../Login/Login";
import { useLogin } from "../Login/LoginContext";
import Sidebar from "../../Sidebar/Sidebar";
import AddSymbol from "../AddSymbol/AddSymbol";
import { WatchlistProvider } from "../Home/WatchlistContext";
import EditSymbol from "../EditSymbol/EditSymbol";
import Chart from "../Chart/Chart";
import Message from "../Message/Message";
import { TradeProvider } from "../Trade/TradeContext";
import { MessageProvider } from "../Message/MessageContext";
import News from "../News/News";
import Mailbox from "../Mailbox/Mailbox";
import Setting from "../Setting/Setting";
import BuySell from "../BuySell/BuySell";
import ManageAccount from "../ManageAccount/ManageAccount";
import Journal from "../Journal/Journal";
import ChangePassword from "../ChangePassword/ChangePassword";
import { BuySellProvider } from "../BuySell/BuySellContext";
import Properties from "../OrderScreens/Properties";
import Statatics from "../OrderScreens/Statatics";
import EditPending from "../EditPending/EditPending";
import { App } from "@capacitor/app";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useLogin();

  return isLoggedIn ? children : <Navigate to="/login" />;
};

const AppRoutes = () => {
  const { isLoggedIn } = useLogin();
  const location = useLocation();
  const [state, setstate] = useState(0);

  const isLoginPage = location.pathname === "/login";
  const navigate = useNavigate();

  useEffect(() => {
    const backHandler = App.addListener("backButton", (e) => {
      if (
        window.location.pathname == "/login" ||
        window.location.pathname == "/home"
      ) {
        App.exitApp();
      } else {
        navigate(-1);
      }
      e.preventDefault();
    });
    return () => {
      // backHandler.remove(); // Clean up the listener on component unmount
    };
  }, []);

  return (
    <>
      {isLoggedIn && <Sidebar />}

      {!isLoggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={<Navigate to={isLoggedIn ? "/home" : "/login"} />}
          />
          <Route
            path="*"
            element={<Navigate to={isLoggedIn ? "/home" : "/login"} />}
          />
        </Routes>
      ) : (
        <MessageProvider>
          <Routes>
            <Route
              path="/home"
              element={
                <WatchlistProvider>
                  <TradeProvider>
                    <Home />
                  </TradeProvider>
                </WatchlistProvider>
              }
            />

            <Route path="/history" element={<History />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/statatics" element={<Statatics />} />
            <Route
              path="/editpending"
              element={
                <BuySellProvider>
                  <EditPending />
                </BuySellProvider>
              }
            />
            <Route path="/news" element={<News />} />
            <Route path="/mailbox" element={<Mailbox />} />
            <Route
              path="/setting"
              element={
                <BuySellProvider>
                  <Setting />
                </BuySellProvider>
              }
            />
            <Route
              path="/buysell"
              element={
                <WatchlistProvider>
                  <BuySellProvider>
                    <BuySell />
                  </BuySellProvider>
                </WatchlistProvider>
              }
            />
            <Route path="/journal" element={<Journal />} />
            <Route path="/password" element={<ChangePassword />} />

            <Route path="/chart" element={<Chart />} />
            <Route path="/manage" element={<ManageAccount />} />

            <Route path="/message" element={<Message />} />
            <Route
              path="/trade"
              element={
                <TradeProvider>
                  <Trade />
                </TradeProvider>
              }
            />
            <Route
              path="/addsymbol"
              element={
                <WatchlistProvider>
                  <AddSymbol />
                </WatchlistProvider>
              }
            />
            <Route
              path="/editsymbol"
              element={
                <WatchlistProvider>
                  <EditSymbol />
                </WatchlistProvider>
              }
            />

            <Route
              path="/"
              element={<Navigate to={isLoggedIn ? "/home" : "/login"} />}
            />
            <Route
              path="*"
              element={<Navigate to={isLoggedIn ? "/home" : "/login"} />}
            />
          </Routes>
        </MessageProvider>
      )}
    </>
  );
};

export default AppRoutes;
