const api_link = "https://routes.dfcapitals.com/user";
const access_key =
  "040ec1ee950ffc53291f6df0ffc30325-a1d41284cbad95a80f076eed39859381.77004ea213d5fc71acf74a8c9c6795fb";


export async function Auth(path, data) {
  try {
    const response = await fetch(api_link + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: { ...data, },
        access_key: access_key,
      }),
    });

    const jsonResponse = await response.json();
    // console.log(jsonResponse);
    // alert(JSON.stringify(jsonResponse))

    if (jsonResponse.error !== "False") {
      window.addNotification(jsonResponse.message,'error')
      return false
      
    } else{
      return jsonResponse;
    }
  } catch (error) {
    console.error("API call failed:", error); // Log any errors
    // throw error; // Rethrow the error to be handled by the calling function
  }
}
// }
