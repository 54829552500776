import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa6";
import { Theme } from '../../Theme/Theme';
import { MdCurrencyExchange } from "react-icons/md";
import { Menu } from 'primereact/menu';
import { useWatchlist } from '../Home/WatchlistContext';

export default function BuysellTopcard() {
    const navigate = useNavigate()

    const menuLeft = useRef(null);
    const [items,setItems] = useState([])
    const [scriptdata,setscriptdata] =  useState(JSON.parse(localStorage.getItem("script")) || {});

    const {watchlist} =useWatchlist()




  

    useEffect(()=>{

      const newdatas = []
watchlist.map(i=>{
  newdatas.push({
    label:i.name,
    command :()=>{
      changeWatchlist(i)
    }
  })

  setItems(newdatas)
})

    },[watchlist])


const changeWatchlist =(e)=>{

  localStorage.setItem("script",JSON.stringify(e))
  setscriptdata(e)
  window.setscriptdata(e)

  

}
    
  return (
    <div style={{display:'flex',height:60,alignItems:'center'}}>
            <div onClick={()=>navigate(-1)} style={{ width:50,display:'flex',justifyContent:'center',alignItems:'center'}}>
                <FaArrowLeft size={20}/>
            </div>
            <div style={{flex:1}}>
                <div style={{fontSize:18}}>{scriptdata.name&&scriptdata.name}{scriptdata.symbol&&scriptdata.symbol}</div>
                {/* <div style={{fontSize:13,color:Theme.MEDIUMGRAY}}>{scriptdata.name}</div> */}
            </div>

            <div 
              onClick={(event) => menuLeft.current.toggle(event)}
            style={{ width:50,display:'flex',justifyContent:'center',alignItems:'center',marginRight:20}}>
                <MdCurrencyExchange size={25}/>
            </div>
            <Menu model={items} popup ref={menuLeft} style={{width:'auto',paddingBottom:0,marginRight:10}}  id="popup_menu_right" popupAlignment='right' />
    </div>
  )
}
