import React from "react";
import { useWindowSize } from "@react-hook/window-size";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import SelectServer from "./SelectServer";
import { useNotification } from "../../Notification/NotificationContext";
import axios from "axios";
import { Auth } from "../Backend/Auth";
import { useLogin } from "./LoginContext";
import { useNavigate } from "react-router-dom";
import { Image } from "primereact/image";

export default function Login() {
  const [width, height] = useWindowSize();
  const logosize = (height / 10) * 3;
  const formwidth = (width / 10) * 8.5;
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [ip, setIp] = React.useState("");
  const { login } = useLogin();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");

        setIp(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIp();
  }, []);

  const [formdata, setFormdata] = React.useState({
    sr: "",
    un: "",
    pw: "",
  });

  const Login = async () => {
    if (formdata.sr == "") {
      window.addNotification("Enter Server", "error");

      // ToastAndroid.show('Enter Server', ToastAndroid.LONG);
    } else if (formdata.un == "") {
      window.addNotification("Enter Username", "error");
    } else if (formdata.pw == "") {
      window.addNotification("Enter Password", "error");
    } else {
      if (!loading) {
        // setLoading(true);
        let data = {
          ...formdata,
          ip: ip,
          device: "web",
          // version: DeviceInfo.getBuildNumber(),
        };
        const response = await Auth("/user_login", data);
        if (!response) {
          return false;
        }

        localStorage.setItem("token", response.jwt);
        localStorage.setItem("server", response.sv);
        localStorage.setItem("client", response.users_id);
        localStorage.setItem("client_details", JSON.stringify(response.users));
        login();
        setTimeout(() => {
          navigate("/home");
        }, 200);

        // localStorage.setItem('IsLoggedIn', '1');
        // props.token(r.jwt);
        // props.id(r.users_id);
        // props.server(r.sv);
        // props.isLogin(1);
      }
    }
  };

  return (
    <div style={{ height: height, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          height: logosize,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
        }}
      >
        <Image src={require("../../Theme/logo.png")} width="100" />
      </div>
      <div style={{ flex: 1, width: formwidth, alignSelf: "center" }}>
        {/* <InputText placeholder='Server'  onClick={() => setVisible(true)} /> */}
        <div
          onClick={() => setVisible(true)}
          style={{
            padding: 10,
            borderStyle: "solid",
            borderRadius: 5,
            borderColor: "lightgray",
            height: 50,
            color: "gray",
            fontSize: 15,
            display: "flex",
            alignItems: "center",
            fontWeight: "normal",
          }}
        >
          SERVER : {formdata.sr}
        </div>

        <div
          style={{
            borderStyle: "solid",
            borderRadius: 5,
            borderColor: "lightgray",
            height: 50,
            marginTop: 10,
          }}
        >
          <input
            placeholder="USERNAME"
            onChange={(e) => setFormdata({ ...formdata, un: e.target.value })}
            style={{
              width: "100%",
              height: "100%",
              borderWidth: 0,
              borderRadius: 5,
              paddingLeft: 10,
            }}
          />
        </div>
        <div
          style={{
            borderStyle: "solid",
            borderRadius: 5,
            borderColor: "lightgray",
            height: 50,
            marginTop: 10,
          }}
        >
          <input
            onChange={(e) => setFormdata({ ...formdata, pw: e.target.value })}
            placeholder="PASSWORD"
            style={{
              width: "100%",
              height: "100%",
              borderWidth: 0,
              borderRadius: 5,
              paddingLeft: 10,
            }}
          />
        </div>

        <Button
          onClick={Login}
          style={{
            width: "100%",
            marginTop: 10,
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            height: 50,
          }}
        >
          LOGIN
        </Button>
      </div>

      <Dialog
        header="Select Server"
        visible={visible}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        dismissableMask
        style={{ width: "100vw", height: "100vh", margin: 0, paddingTop: 20 }}
      >
        <SelectServer
          onClick={(e) => {
            setFormdata({ ...formdata, sr: e.server });
            setVisible(false);
          }}
        />
      </Dialog>
    </div>
  );
}
