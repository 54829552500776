import React, { useMemo, useEffect, useState } from 'react';
import { Theme } from '../../Theme/Theme';
import BidAskCard from './BidAskCard';
import { useSocket } from '../../Socket/SocketContext';
import moment from 'moment';
import { BiCollapseVertical } from "react-icons/bi";
import { useTradeContext } from '../Trade/TradeContext';
import { BiSolidLeftArrow } from "react-icons/bi";


export default function SimpleMode({ openwindow, item }) {
  const [pfl,setPfl]=useState(0)
  const { subscribeToSymbol, data,socket } = useSocket();

  const { GetProfitLoss} = useTradeContext()
  const [scriptData,setScriptdata] = useState({})

  const scriptId = item.script_id;


  const handleTrade = (data) => {
    setScriptdata((prevData) => ({ ...prevData, ...data }));
  };


const handleBidask = (data) => {
  setScriptdata((prevData) => ({
    ...prevData,
    Bid: data.Bid,
    Ask: data.Ask,
  }));
};


  // const scriptData = data[scriptId] || {};


useEffect(()=>{

  socket?.on(`trade${scriptId}`, handleTrade);
  socket?.on(`bidask${scriptId}`, handleBidask);


  socket?.emit("giverate",scriptId)

  return () => {
    socket?.off(`trade${scriptId}`, handleTrade);
    socket?.off(`bidask${scriptId}`, handleBidask);
  };
},[socket])




  useEffect(() => {
    const pfls = GetProfitLoss(scriptId,scriptData);
    setPfl(pfls);
  }, [scriptId, scriptData, GetProfitLoss]);


  // const chang = useMemo(() => {
  //   const changeValue = (scriptData.LTP || 0) - (scriptData.Previous_Close || 0);
  //   return isNaN(changeValue) ? 0 : changeValue;
  // }, [scriptData.LTP, scriptData.Previous_Close]);

  // const spread = useMemo(() => {
  //   const spreadValue = (scriptData.Ask || 0) - (scriptData.Bid || 0);
  //   return isNaN(spreadValue) ? 0 : spreadValue;
  // }, [scriptData.Ask, scriptData.Bid]);

  // const percent = useMemo(() => {
  //   if (scriptData.Previous_Close) {
  //     const percentValue = (chang / scriptData.Previous_Close) * 100;
  //     return isNaN(percentValue) ? 0 : percentValue;
  //   }
  //   return 0;
  // }, [chang, scriptData.Previous_Close]);
  // const percent = useMemo(() => (scriptData.Previous_Close ? (chang / scriptData.Previous_Close) * 100 : 0), [chang, scriptData.Previous_Close]);

  const formattedTimestamp = useMemo(() => moment(scriptData.Timestamp).format("HH:mm:ss"), [scriptData.Timestamp]);

  return (
    <div style={{ padding: 10, display: 'flex',position:'relative' }} onClick={openwindow}>
{pfl>0 || pfl<0?
<div style={{
  position: 'absolute',
  height: 15,
  width: 15,
  top: -5,
  left: -10,
  transform: 'rotate(45deg)',
  transformOrigin: 'center', // Optional: Adjusts the pivot point for rotation
}}>
  <BiSolidLeftArrow color={pfl<0?Theme.REDCOLOR:Theme.FONTCOLOR} size={20} />
</div>:null}



      <div style={{ flex: 3 }}>
  
        <div style={{ fontSize: 19 }}>{item.name}</div>
 
      </div>

      <BidAskCard  bid={scriptData.Bid} />
      <BidAskCard  bid={scriptData.Ask}  />
    </div>
  );
}

