import React from 'react'
import { Theme } from '../../Theme/Theme'

export default function SearchServerCard({image,name,details,onClick}) {
    
  return (
    <div onClick={onClick} style={{height:60,borderStyle:'solid',borderWidth:0,borderBottomWidth:0.2,display:'flex'}}>
       <div style={{width:70,height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <img src={image} style={{height:45,width:45,}}/>
       </div>
       <div style={{flex:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <div style={{fontWeight:'bold',color:Theme.FONTCOLOR}}>{name}</div>
        <div style={{fontSize:12,color:Theme.DARKGRAY}}>{details}</div>
       </div>
    </div>
  )
}
