import React from 'react'
import { Theme } from '../Theme/Theme'

export default function TopCardItem({name,value}) {
  return (
    
    <div style={{display:'flex',marginTop:10}}>
    <div style={{paddingRight:10,fontSize:15}}>{name}: </div>
    <div style={{
    flex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',
    fontSize:10,
    marginTop:3,
    color: Theme.LIGHTGRAY
}}>
    {/* Using dots instead of dashes */}
    {Array(100).fill('•').join(' ')}
</div>
    <div style={{paddingLeft:10,fontSize:15}}>{value}</div>
    </div>
  )
}
