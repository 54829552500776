import React from "react";

export default function PropertiesCard({ name, value }) {
  return (
    <div
      style={{ height: 40, display: "flex", alignItems: "center", padding: 5 }}
    >
      <div style={{ flex: 1 }}>{name}</div>
      <div>{value}</div>
    </div>
  );
}
