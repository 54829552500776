import { useWindowSize } from "@react-hook/window-size";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Theme } from "../../Theme/Theme";
import PropertiesCard from "./PropertiesCard";
import { useSocket } from "../../Socket/SocketContext";
import moment from "moment";

export default function Properties() {
  const [width, height] = useWindowSize();
  const [scriptdata, setscriptdata] = useState(
    JSON.parse(localStorage.getItem("script")) || {}
  );

  const { subscribeToSymbol, socket } = useSocket();

  const [bidaskdata, setBidaskdata] = useState({});

  const navigate = useNavigate();

  const scriptId = scriptdata.script_id;

  const handleTrade = (data) => {
    setBidaskdata((prevData) => ({ ...prevData, ...data }));
  };

  const handleBidask = (data) => {
    setBidaskdata((prevData) => ({
      ...prevData,
      Bid: data.Bid,
      Ask: data.Ask,
    }));
  };

  useEffect(() => {
    socket?.on(`trade${scriptId}`, handleTrade);
    socket?.on(`bidask${scriptId}`, handleBidask);

    socket?.emit("giverate", scriptId);

    return () => {
      socket?.off(`trade${scriptId}`, handleTrade);
      socket?.off(`bidask${scriptId}`, handleBidask);
    };
  }, [scriptId, socket]);

  return (
    <div style={{ height: height }}>
      <div style={{ height: 50, display: "flex", alignItems: "center" }}>
        <div
          onClick={() => navigate(-1)}
          style={{
            width: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FaArrowLeft size={20} />
        </div>

        <div style={{ flex: 1 }}>
          <div style={{ fontSize: 18 }}>
            {scriptdata.name && scriptdata.name}
            {scriptdata.symbol && scriptdata.symbol}
          </div>
          <div style={{ fontSize: 13, color: Theme.MEDIUMGRAY }}>
            {scriptdata.name}
          </div>
        </div>
      </div>

      {/* properties section */}
      <div
        style={{
          padding: 5,
          fontSize: 15,
          background: Theme.LIGHTGRAY,
          height: 40,
          display: "flex",
          alignItems: "center",
        }}
      >
        Properties
      </div>

      <PropertiesCard name={"Trading Type"} value={"QTY WISE"} />
      <PropertiesCard name={"Segment"} value={"NSE/EQUITY"} />
      <PropertiesCard name={"Tick Size"} value={1} />
      <PropertiesCard name={"SYMBOL"} value={"NSE/" + scriptdata.name} />
      <PropertiesCard name={"LTP"} value={bidaskdata.LTP} />
      <PropertiesCard name={"HIGH"} value={bidaskdata.High} />
      <PropertiesCard name={"LOW"} value={bidaskdata.Low} />
      <PropertiesCard name={"OPEN"} value={bidaskdata.Open} />
      <PropertiesCard
        name={"PREVIOUS CLOSE"}
        value={bidaskdata.Previous_Close}
      />
      <PropertiesCard name={"VOLUME"} value={bidaskdata.Volume} />
      <PropertiesCard
        name={"TICK TIME"}
        value={moment(bidaskdata.Timestamp).format("DD-MM-YY HH:mm:ss")}
      />
    </div>
  );
}
