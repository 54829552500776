import React, { createContext, useContext, useState, useEffect } from 'react';
import { apiCall } from '../Backend/Api';
import { useSocket } from '../../Socket/SocketContext';

const WatchlistContext = createContext();

export const WatchlistProvider = ({ children }) => {
  const [watchlist, setWatchlist] = useState([]);
  const [allScripts, setAllScripts] = useState([]);
  const [loading, setLoading] = useState(true);
  const {subscribeToSymbol} =useSocket()

  // Fetch initial watchlist data
  const loadWatchlist = async () => {
    try {
      const response = await apiCall("/loadwatchlist");
      setWatchlist(response?.data || []);

    } catch (error) {
      console.error("Failed to load watchlist:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch all scripts data
  const loadAllScripts = async () => {
  
   
    try {
      const response = await apiCall("/all_script");
  
      // Filter out scripts that are already in the watchlist
      const filteredScripts = response?.data.filter(script =>
        !watchlist.some(watchlistItem => watchlistItem.script_id === script.script_id)
      ) || [];
      setAllScripts(filteredScripts);
    } catch (error) {
      console.error("Failed to load all scripts:", error);
    }
  };

  // useEffect(() => {
  //   // Load watchlist and all scripts data on component mount
   
  //   watchlist.map((i)=>{
  //     subscribeToSymbol(i.script_id)
  //   })
  // }, [watchlist]);

  useEffect(() => {
    // Load watchlist and all scripts data on component mount
    loadWatchlist();
  }, []);

  useEffect(() => {
    // Load all scripts data after watchlist is loaded
    if (!loading) {
      loadAllScripts();
    }
  }, [ loading]);

  // Add item to watchlist
  const addToWatchlist = async (item) => {
    // subscribeToSymbol(item.script_id)
    // Update local state immediately
    setWatchlist((prevWatchlist) => [...prevWatchlist, item]);
  
    // Remove the item from allScripts state immediately
    setAllScripts((prevAllScripts) => prevAllScripts.filter(script => script.script_id !== item.script_id));
  const data={
    script:item

  }
    // Perform background API call
   const response = await apiCall("/addscript",data)
      // .catch(error => {
      //   console.error("Failed to add item to watchlist:", error);
      //   // Optionally handle API call failure here (e.g., rollback state change or notify user)
      //   // Re-add the item to allScripts if needed
      //   setAllScripts((prevAllScripts) => [...prevAllScripts, item]);
      // });
  };


  

  // Remove item from watchlist
  const removeFromWatchlist = async (itemIds) => {
    // Ensure itemIds is an array
    if (!Array.isArray(itemIds)) {
      console.error("Expected an array of item IDs");
      return;
    }

    // Update local state immediately
    setWatchlist((prevWatchlist) => prevWatchlist.filter(item => !itemIds.includes(item.script_id)));

    // Perform background API calls
    itemIds.forEach(async itemId => {
   
      const data={
        script_id:itemId
    
      }
      // Perform API call in the background
      const response = await apiCall("/removescript",data)
     
    });
  };

  return (
    <WatchlistContext.Provider value={{ watchlist, allScripts, addToWatchlist, removeFromWatchlist, loading ,setWatchlist}}>
      {children}
    </WatchlistContext.Provider>
  );
};

export const useWatchlist = () => useContext(WatchlistContext);
