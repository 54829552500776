import React, { useState } from "react";
import { Theme } from "../../Theme/Theme";
import { FaLongArrowAltRight } from "react-icons/fa";
import moment from "moment";

export default function PositionItemCard({openwindow,data}) {
  
  const [timer, setTimer] = useState(null);
  const [hide,setHide] =useState(false)

 
  return (
 
    <div
      style={{
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        borderWidth: 0,
        borderStyle: "solid",
        borderBottomWidth: 0.5,
        borderColor: Theme.MEDIUMGRAY,
      }}
      onClick={()=>setHide(!hide)}
    >
      <div
      onClick={openwindow}
        
        style={{ display: "flex" }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ display: "flex", fontSize: 18,fontWeight:'bold' }}>
            <div style={{fontSize:16}}>{data.symbol}</div>
            {data.script_id >0&&<div
              style={{ marginLeft: 5, color: data.buy_sell ==0?Theme.FONTCOLOR:Theme.REDCOLOR, fontSize: 14 }}
            >
              {data.buy_sell ==0?"BUY":"SELL"} <span style={{ marginLeft: 5,fontSize:14 }}>{data.qty}</span>
            </div>}
          </div>
          {data.script_id >0&&  <div style={{ display: "flex", color: Theme.DARKGRAY,fontWeight:'bold' }}>
            <div style={{fontSize:14}}>{parseFloat(data.position_rate)}</div>

            <div
              style={{
                height: 17,
                marginLeft: 5,
                marginRight: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaLongArrowAltRight />
            </div>
            <div style={{fontSize:14}}>{data.rate}</div>
          </div>}
        </div>
        <div style={{ color: Theme.FONTCOLOR, fontSize: 16,display:'flex',flexDirection:'column',alignItems:'flex-end' }}>
            <div style={{fontSize:10,color:Theme.MEDIUMGRAY,fontWeight:'bold'}}>{moment(data.date_created).format("DD-MMM-YY hh:mm:ss A")}</div>
            <div style={{color:data.profit_loss>=0?Theme.FONTCOLOR:Theme.REDCOLOR}}>{data.profit_loss}</div>
            <div style={{color:Theme.MEDIUMGRAY,fontSize:12}}>{data.trade_msg}</div>
            </div>
      </div>
      {hide&&
<div style={{fontSize:12,color:Theme.MEDIUMGRAY}}>
      <div>Trade Id # {data.id}</div>
      <div>Commision : {data.brokerage_amt}</div>
      </div>}
    </div>
    

  );
}
